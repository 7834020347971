var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list && _vm.list.length
    ? _c(
        "div",
        { staticClass: "newspaper-page" },
        [
          _c("div", { staticClass: "newspaper-page__filter" }, [
            _c(
              "select",
              { staticClass: "js-select", on: { change: _vm.filterHandler } },
              [
                _c("option"),
                _vm._v(" "),
                _vm._l(_vm.filters.year, function(item, index) {
                  return _c("option", {
                    key: index,
                    domProps: { value: item.id, innerHTML: _vm._s(item.text) }
                  })
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "newspaper-page__list" },
            _vm._l(_vm.list, function(item) {
              return _c("newspaper-page-item", {
                key: item.name,
                staticClass: "newspaper-page__item",
                attrs: { "item-data": item }
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.isLoading ? _c("z-preloader") : _vm._e(),
          _vm._v(" "),
          _vm.paginationData
            ? _c("z-pagination", {
                staticClass: "u-top-margin--xl-important",
                attrs: {
                  "show-next": "",
                  "show-prev": "",
                  data: _vm.paginationData
                },
                on: { "change-page": _vm.paginationHandler }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-74289c74", { render: render, staticRenderFns: staticRenderFns })
  }
}