import Vue from 'vue'

const container = document.getElementById('scrap-metal')
const exists = typeof (container) != 'undefined' && container != null

import ScrapMetal from './ScrapMetal.vue'

if (exists) {
    new Vue({
        el: '#scrap-metal',
        components: {
            ScrapMetal,
        },
        data: {
            lang: getLang(),
            app: window.App
        },
    })
};
