import Vue from 'vue';
import ProjectsPage from './ProjectsPage.vue';

const container = document.getElementById('projects-page');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) ProjectsPageApp();

function ProjectsPageApp() {
    new Vue({
        el: '#projects-page',
        components: {
            ProjectsPage
        },
        data: {
            app: window.App
        },
    });
};
