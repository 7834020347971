export const text = {
    materialName: {
        title: 'Наименование материала или группы материалов*',
        placeholder: 'Например: Втулка ВГШ БВ148 (запасные части, подшипники, кабель)'
    },
    brandEngine: {
        title: 'Марка двигателя',
        placeholder: 'Например: 6НВД48-2У'
    },
    number: {
        title: 'Номенклатурный номер или номер по порядку*',
        placeholder: 'Например: 232000181 (с 1 по 6)'
    },
    amount: {
        title: 'Количество единиц*',
        placeholder: 'Кол-во в штуках, метрах, кг и т.д.'
    },
    price: {
        title: 'Стоимость реализации ориентировочно',
        placeholder: 'Ориентировочная стоимость материала или группы материалов'
    },
    organizationName: {
        title: 'Наименование организации*',
        placeholder: 'Название организации или ПБЮЛ, иначе укажите - «частное лицо»'
    },
    fio: {
        title: 'ФИО контактного лица*',
        placeholder: 'Полное ФИО контактного лица организации или частного лица'
    },
    contactInfo: {
        title: 'Контактная информация*',
        placeholder: 'Ваш e-mail, телефон (с кодом города), факс и другие контакты'
    },
    additionalInfo: {
        title: 'Дополнительная информация',
        placeholder: 'Любая важная с Вашей точки зрения дополнительная информация'
    },
    captcha: 'Введите код с картинки*',
    button: 'Отправить',
    notificationRequiredTextError: 'Заполните все обязательные поля',
    notificationError: 'Ошибка отправки',
    notificationValidateError: 'Неправильный формат полей',
    notificationSuccess: 'Отправка успешна',
    notificationCaptchaError: 'Неверный код капчи. Попробуйте снова.',
    notificationCaptchaEmpty: 'Введите код с картинки',
    policy: 'Я ознакомился с Политикой о защите персональных данных и даю свое согласие на обработку моих персональных данных.'
}