<template>
  <div class="header-menu-wrapper" v-click-outside="hideChilds">
    <nav
        :class="[
                'header-menu-nav-links',
                { 'is-opened': navIndexOpened !== -1 },
            ]"
    >
      <ul class="header-menu-nav-links__list">
        <li
            :class="[
                        'header-menu-nav-links__list-item',
                        {
                            'header-menu-nav-links__list-item--opened':
                                indexA === navIndexOpened,
                            'is-active': itemA.active,
                        },
                    ]"
            v-for="(itemA, indexA) in data"
            :key="indexA"
        >
          <a
              :href="getHref(itemA)"
              :class="[
                            'header-menu-nav-links__item-link',
                            {
                                'header-menu-nav-links__item-link--is-parent':
                                    itemA.items && itemA.items.length,
                            },
                        ]"
              @click="showChilds($event, indexA, itemA)"
              :target="isBlank(itemA)"
          >
                        <span
                            class="header-menu-nav-links__link-name"
                            v-html="itemA.name"
                        ></span>
            <span class="header-menu-nav-links__link-arrow">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M7.77652 10.4243C7.88904 10.5368 8.04165 10.6 8.20078 10.6C8.35991 10.6 8.51252 10.5368 8.62505 10.4243L12.625 6.42429C12.8594 6.18997 12.8594 5.81008 12.625 5.57576C12.3907 5.34145 12.0108 5.34145 11.7765 5.57576L8.20078 9.1515L4.62505 5.57576C4.39073 5.34145 4.01083 5.34145 3.77652 5.57576C3.5422 5.81007 3.5422 6.18997 3.77652 6.42429L7.77652 10.4243Z"
                                      fill="#ECEEF3" />
                            </svg>
                        </span>
          </a>
        </li>
      </ul>
    </nav>

    <div class="header-menu">
      <ul class="header-menu__list">
        <li
            :class="[
                        'header-menu__list-item',
                        {
                            'header-menu__list-item--opened': navIndexOpened === indexA,
                        },
                    ]"
            v-for="(itemA, indexA) in data"
            :key="indexA"
        >
          <div
              class="header-menu__submenu-wrapper container"
              v-if="itemA.items && itemA.items.length"
          >
            <div class="u-relative">
              <button type="button" class="header-menu__button" @click="hideChilds">
                <svg version="1.1" viewBox="0 0 24 24" class="header-menu__button-icon z-icon z-fill"
                     style="width: 32px; height: 32px;">
                  <path pid="0" fill-rule="evenodd" clip-rule="evenodd"
                        d="M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z"
                        fill="#0077C8"></path>
                </svg>
              </button>
              <div class="header-menu__submenu-heading">
                <a
                    v-if="!isRedirect(itemA)"
                    :href="getHref(itemA)"
                    :decorLeft="true"
                    theme="white"
                    size="m"
                    uppercase
                    weight="700"
                    class="u-bottom-margin--xs-important"
                >
                  {{ itemA.name }}
                </a>
              </div>
              <div class="header-menu__row">
                <div class="header-menu__col header-menu__col--1">
                  <ul class="header-menu-item">
                    <template
                        v-for="(itemB, indexB) in itemA.items"
                    >
                      <li
                          class="header-menu-item__list-item"
                          :class="{
                                                    'is-active': itemB.active,
                                                    'is-opened': secondObj !== null && secondObj.link === itemB.link && secondObj.name === itemB.name
                                                }"
                          :key="indexB"
                      >
                        <a
                            :href="getHref(itemB)"
                            @click="showNextLvl($event, itemB, '2')"
                            class="header-menu-item__item-link"
                        >
                          <span class="header-menu-item__item-link-name" v-html="itemB.name"></span>
                          <span v-if="itemB.parent" class="header-menu-item__item-link-arrow">
                                                        <svg style="transform: rotate(-90deg);" width="17" height="16" viewBox="0 0 17 16"
                                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M7.77652 10.4243C7.88904 10.5368 8.04165 10.6 8.20078 10.6C8.35991 10.6 8.51252 10.5368 8.62505 10.4243L12.625 6.42429C12.8594 6.18997 12.8594 5.81008 12.625 5.57576C12.3907 5.34145 12.0108 5.34145 11.7765 5.57576L8.20078 9.1515L4.62505 5.57576C4.39073 5.34145 4.01083 5.34145 3.77652 5.57576C3.5422 5.81007 3.5422 6.18997 3.77652 6.42429L7.77652 10.4243Z"
                                                                  fill="#ECEEF3" />
                                                        </svg>
                                                    </span>
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
                <div
                    class="header-menu__col header-menu__col--2"
                    :style="{
                                        'opacity': secondObj !== null ? '1': '0'
                                    }"
                >
                  <div
                      v-if="secondObj"
                      class="header-menu__submenu-heading"
                      :class="{
                                            'is-active': secondObj.active,
                                        }"
                  >

                                        <span
                                            v-if="isRedirect(secondObj)"
                                            :href="isRedirect(secondObj) ? false : getHref(secondObj)"
                                            theme="white"
                                            size="l"
                                            class="u-bottom-margin--xs-important"
                                        >
                                        {{ secondObj.name }}
                                        </span>
                    <a
                        v-else
                        :href="isRedirect(secondObj) ? false : getHref(secondObj)"
                        theme="white"
                        size="l"
                        class="u-bottom-margin--xs-important"
                    >
                      {{ secondObj.name }}
                    </a>
                  </div>
                  <ul
                      class="header-submenu"
                      v-if="
                                            secondObj &&
                                            secondObj.items &&
                                            secondObj.items.length
                                        "
                  >
                    <li
                        class="header-submenu__item"
                        :class="{
                                                'is-active': itemC.active,
                                            }"
                        v-for="(
                                                itemC, indexC
                                            ) in secondObj.items"
                        :key="indexC"
                    >
                      <a
                          :href="getHref(itemC)"
                          @click="showNextLvl($event, itemC, '3')"
                          class="header-submenu__item-link"
                      >
                                                <span
                                                    class="header-submenu__link-text"
                                                    v-html="itemC.name"
                                                ></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation-main',
  props: {
    columnsCount: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      data: [],
      hovered: null,
      subMenu: false,
      subMenuItems: [],
      navIndexOpened: -1,
      secondObj: null
    }
  },
  created () {
    let list = []
    let cacheI = 0
    App.components.navigation.top.forEach((item, i) => {
      let obj = {
        type: 'link',
        index: i,
        name: item.TEXT,
        link: item.LINK,
        level: Number(item.DEPTH_LEVEL),
        parent: item.IS_PARENT,
        permission: item.PERMISSION,
        params: item.PARAMS,
        active: item.SELECTED
      }
      if (obj.parent) {
        obj.items = []
      }

      if (obj.level > 1) {
        const parent = item.CHAIN[item.CHAIN.length - 2]
        const parentLevel = obj.level - 1
        let objParent = this.deepSearch(list, 'level', (k, v) => v === parentLevel, parent)
        objParent.items.push(obj)
      } else {
        list.push(obj)
      }

      if (obj.parent) {
        cacheI = i
      }
    })

    this.data = list
  },
  methods: {
    deepSearch (object, key, predicate, parent) {
      if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
        if (object.name === parent) {
          return object
        }
      }

      for (let i = 0; i < Object.keys(object).length; i++) {
        let value = object[Object.keys(object)[i]];
        if (typeof value === "object" && value != null) {
          let o = this.deepSearch(object[Object.keys(object)[i]], key, predicate, parent)
          if (o != null) return o
        }
      }
      return null
    },
    showNextLvl (event, item) {
      if (item.parent) {
        event.preventDefault()
        this.secondObj = item
      }
    },
    showChilds (event, i, item) {
      this.secondObj = null
      if (item.hasOwnProperty('items') && item.items.length) {
        event.preventDefault()
        this.navIndexOpened === i
            ? this.hideChilds()
            : (this.navIndexOpened = i)
      } else {
        this.hideChilds()
      }
    },
    hideChilds () {
      this.secondObj = null
      this.navIndexOpened = -1
    },
    getHref (item) {
      if (Array.isArray(item.params)) {
        return item.link
      }

      if (item.params.REDIRECT) {
        return item.params.REDIRECT
      } else {
        return item.link
      }
    },
    isRedirect (item) {
      if (Array.isArray(item.params)) {
        return false
      }

      if (item.params.REDIRECT) {
        return true
      } else {
        return false
      }
    },
    isBlank (item) {
      return (item.blank) ? '_blank' : null
    }
  }
}
</script>