<template>
    <div
        :class="[
            'z-input',
            {
                'is-focused': isFocused,
                'is-errored': isValid === false,
                'is-valid': isValid,
                'is-filled': inputValue !== '' && typeof inputValue !== 'object',
                'is-disabled': disabled
            }
        ]"
    >
        <label class="z-input__label">
            <div class="z-input__title-container">
                <p class="z-input__title" v-if="title" v-html="title"></p>
            </div>
            <div class="z-input__container">
                <input
                    class="z-input__input"
                    name="name"
                    type="text"
                    v-bind="$attrs"
                    @blur="onBlur"
                    @focus="onFocus"
                    @input="onInput($event)"
                    @change="onChange($event)"
                    v-mask="mask"
                    v-model="inputValue"
                    :disabled="disabled"
                    @keypress="onKeyPress"
                    @keypress.enter="onPressEnter"
                />
                <span v-show="placeholder" v-html="placeholder" class="z-input__placeholder"></span>

                <span v-if="isShowClearIcon" @click.stop="onClear">
                    <svg version="1.1" viewBox="0 0 24 24" class="z-input__icon z-input__icon--clear z-icon z-fill z-top" style="width: 24px; height: 24px;">
                        <g clip-path="url(#clip0_2646_22105)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z" fill="#0077C8"></path></g>
                        <defs><clipPath id="clip0_2646_22105"><path pid="1" fill="#fff" d="M0 0h24v24H0z"></path></clipPath>
                        </defs>
                    </svg>
                </span>

            </div>
        </label>
        <span
            :class="['z-input__error', errorClass]"
            v-html="error"
            v-if="error && !isValid"
        ></span>
    </div>
</template>

<script>
import { VueMaskDirective as mask } from 'v-mask'

export default {
    name: 'z-input',
    inheritAttrs: false,
    props: {
        title: String,
        placeholder: String,
        value: [Number, String],
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            validator: (prop) =>
                ['text', 'number', 'tel', 'email', 'link', 'text-only'].includes(prop),
            default: 'text'
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        mask: {
            type: String,
            default: ''
        },
        errorClass: {
            type: String,
            default: ''
        },
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        positive: {
            type: Boolean,
            default: true
        },
        iconName: {
            type: String
        },
        clearable: {
            type: Boolean,
            default: true
        },
        minVal: [String, Number],
        maxVal: [String, Number]
    },
    directives: {
        mask
    },
    data () {
        return {
            localValue: null,
            isFocused: false,
            isValid: null,
            error: false,
            text: {
                errors: {
                    required: {
                        ru: 'Поле обязательно для заполнения',
                        en: 'Required field',
                        cn: '填项目'
                    },
                    invalid: {
                        email: {
                            ru: 'Формат для e-mail указан неверно',
                            en: 'Invalid email format',
                            cn: '无效的电子邮件格式'
                        },
                        url: {
                            ru: 'Неверный формат ссылки',
                            en: 'Invalid link format'
                        },
                        text: {
                            ru: 'Допускаются только буквы и спецсимволы',
                            en: 'Only letters and special characters are allowed'
                        },
                        digits: {
                            ru: 'Допускаются только цифры',
                            en: 'Only digits'
                        },
                        tel: {
                            ru: 'Допускаются только цифры и спецсимволы',
                            en: 'Only numbers and special characters are allowed'
                        },
                        pattern: {
                            ru: 'Неверный формат данных',
                            en: 'Incorrect data format'
                        },
                        minVal: {
                            ru: `Минимальное допустимое значение - ${this.minVal}`,
                            en: `Minimum allowable value - ${this.minVal}`
                        },
                        maxVal: {
                            ru: `Максимальное допустимое значение - ${this.maxVal}`,
                            en: `Maximum allowable value - ${this.maxVal}`
                        }
                    }
                }
            }
        }
    },
    computed: {
        inputValue: {
            get () {
                if (this.value !== undefined) return this.value // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.localValue = value
            }
        },
        isShowClearIcon () {
            if (this.clearable) {
                return !!this.inputValue
            }
            return false
        }
    },
    methods: {
        clearIncompletedField () {
            // может понадобится, пока не используется
            if (this.mask && this.inputValue) {
                if (this.inputValue.length < this.mask.length) {
                    this.$emit('input', '')
                }
            }
        },
        onBlur () {
            this.isFocused = false
            if (this.validateOnBlur) this.validate()
        },
        onFocus () {
            this.isFocused = true
        },
        onInput (e) {
            this.$emit('input', e.target.value)
            if (this.isValid !== null) this.$nextTick(this.validate)
        },
        onClear () {
            this.$emit('input', '')
        },
        onPressEnter (e) {
            this.$emit('enter', e.target.value)
        },
        onKeyPress (e) {
            if (this.type !== 'number') return
            if (
                this.positive &&
                e.charCode > 31 &&
                (e.charCode < 48 || e.charCode > 57) &&
                e.charCode !== 46
            ) {
                console.log(e.charCode)
                e.preventDefault()
            }
        },
        onChange (e) {
            this.$emit('change', e.target.value)
        },
        validateEmail (email) {
            if (!this.required && !email) {
                return true
            }
            // eslint-disable-next-line no-useless-escape
            const regex =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regex.test(email)
        },
        validateTel (tel) {
            if (!this.required && !tel) {
                return true
            }
            // eslint-disable-next-line no-useless-escape
            const regex = /^[0-9\(\)\/ +_@.-]*$/
            return !!tel.length && regex.test(tel)
        },
        validateUrl (url) {
            // eslint-disable-next-line no-useless-escape
            const regex = /(^https?:\/\/)?[а-яa-z0-9~_\-\.]+\.[а-яa-z]{2,9}(\/|:|\?[!-~]*)?$/i
            return regex.test(url)
        },
        validateTextOnly (str) {
            // eslint-disable-next-line no-useless-escape
            const regex = /^[a-zA-Zа-яА-Я\(\)\/ +_@.-]*$/
            return regex.test(str)
        },
        validateDigitsOnly (str) {
            // eslint-disable-next-line no-useless-escape
            const regex = /^\d+$/
            return regex.test(str)
        },
        validatePattern () {
            return this.inputValue.length === this.mask.length
        },
        validate () {
            if (this.required && !(this.inputValue !== '' && typeof this.inputValue !== 'object')) {
                this.isValid = false
                this.error = localize(this.text.errors.required)
                return
            }

            if (this.type === 'email' && !this.validateEmail(this.inputValue)) {
                this.isValid = false
                this.error = localize(this.text.errors.invalid.email)
                return
            }

            if (this.type === 'link' && !this.validateUrl(this.inputValue)) {
                this.isValid = false
                this.error = localize(this.text.errors.invalid.url)
                return
            }

            if (this.type === 'text-only' && !this.validateTextOnly(this.inputValue)) {
                this.isValid = false
                this.error = localize(this.text.errors.invalid.text)
                return
            }

            if (this.type === 'number' && !this.validateDigitsOnly(this.inputValue)) {
                this.isValid = false
                this.error = localize(this.text.errors.invalid.digits)
                return
            }

            if (this.type === 'tel' && !this.validateTel(this.inputValue)) {
                this.isValid = false
                this.error = localize(this.text.errors.invalid.tel)
                return
            }

            if (this.mask && !this.validatePattern()) {
                this.isValid = false
                this.error = localize(this.text.errors.invalid.pattern)
                return
            }

            if (this.type === 'number') {
                if (this.minVal && Number(this.inputValue) < Number(this.minVal)) {
                    this.isValid = false
                    this.error = localize(this.text.errors.invalid.minVal)
                    return
                }

                if (this.maxVal && Number(this.inputValue) > Number(this.maxVal)) {
                    this.isValid = false
                    this.error = localize(this.text.errors.invalid.maxVal)
                    return
                }
            }

            this.inputValue !== '' && typeof this.inputValue !== 'object'
                ? (this.isValid = true)
                : (this.isValid = null)
            this.error = ''
        }
    }
}
</script>

<style lang="scss">
.z-input {
    $parent: &;
    font-size: 16px;
    width: 100%;

    &__title {
        margin-bottom: 20px !important;
    }

    &__icon {
        position: absolute;
        right: 11px;
        top: 50%;
        transform: translate(0, -50%);

        &--clear {
            cursor: pointer;
        }
    }

    &__container {
        position: relative;

        &:hover {
            .z-input__placeholder {
                color: #4b5368;
            }

            .z-input__input {
                box-shadow: inset 0 0 0 1px #737b90;
            }
        }

        &:focus {
            .z-input__placeholder {
                color: $token-colors-gray-60;
            }

            .z-input__input {
                box-shadow: inset 0 0 0 1px $token-colors-blue;
            }
        }
    }

    &__placeholder {
        display: block;
        @include typo-level(M);
        color: $token-colors-gray-35;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(1);
        left: 16px;
        padding: 0 4px;
    }

    &__title-container {
        display: flex;
        justify-content: space-between;
    }

    &__input {
        box-shadow: inset 0 0 0 1px $token-colors-gray-30;
        transition: box-shadow 0.2s ease-in;
        border-radius: 4px;
        width: 100%;
        color: #1d1d1d;
        box-sizing: border-box;
        padding: 12px 20px;
        padding-right: 40px;
        height: 48px;
        transition: border-color 0.2s ease-in;
        line-height: 1.4;
        font-weight: 600;
        caret-color: $token-colors-blue;
        outline: none;
        border: none;
        background-color: transparent;

        // обнуления стиля для автозаполненных полей
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px $token-colors-white inset !important;
            transition: 0s;
        }
    }

    &__placeholder {
        display: block;
        font-size: 1em;
        line-height: 1.4;
        color: #1d1d1d;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) scale(1);
        left: 16px;
        padding: 0 4px;
    }

    &__error {
        font-size: 12px;
        color: $token-colors-alert-text;
        position: relative;
        margin-left: 20px;
        display: block;
        line-height: 1.2;
        margin-top: 4px;
    }

    // hover state
    &:hover {
        #{$parent}__input {
            border-color: $token-colors-gray-35;
        }
    }

    .z-input__input {
        // Перебивка стилей браузера при автопокмплите
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 1px $token-colors-gray-30 inset, 0 0 0 30px #fff inset !important;
        }
    }

    // filled state
    &.is-filled {
        #{$parent}__placeholder {
            width: auto;
            transform: translateY(-2.4em);
            font-size: 0.75em;
            line-height: 1.2;
            color: $token-colors-gray-50;
            background-color: $token-colors-white;
            font-weight: 600;
        }

        #{$parent}__input {
            border-color: $token-colors-gray-50;
        }

        // Перебивка стилей браузера при автопокмплите
        .z-input__input:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 1px $token-colors-blue inset, 0 0 0 30px #fff inset !important;
        }
    }

    // errored state
    &.is-errored {
        .z-input__input {
            box-shadow: inset 0 0 0 1px $token-colors-alert-text;
        }
    }

    // focused state
    &.is-focused {
        #{$parent}__placeholder {
            width: auto;
            transform: translateY(-32px);
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            color: $token-colors-gray-50;
            background-color: $token-colors-white;
        }

        .z-input__input {
            box-shadow: inset 0 0 0 1px $token-colors-blue;
        }
    }

    // valid state
    &.is-valid {
        .z-input__input {
            box-shadow: inset 0 0 0 1px $token-colors-blue;
        }

        .z-input__placeholder {
            width: auto;
            transform: translateY(-32px);
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            color: $token-colors-gray-50;
            background-color: $token-colors-white;
        }
    }

    // disabled state
    &.is-disabled {
        pointer-events: none;

        .z-input__placeholder {
            color: #737b90;
        }

        .z-input__input {
            box-shadow: inset 0 0 0 1px $token-colors-gray-20;
        }
    }
}
</style>
