<template>
  <div :class="[
    'z-input-file',
    {
      'is-disabled': disabled,
      'is-errored': isValid === false,
      'is-valid': isValid
    }
  ]">
    <label class="z-input-file__label">
      <input
        class="z-input-file__input"
        type="file"
        :name="name"
        ref="file"
        :multiple="multiple"
        :disabled="disabled"
        @change="onChange($event.target)"
        :accept="accept"
      />
      <span class="z-input-file__icon"></span>
      <span v-html="labelText"></span>
    </label>
    <ul
      v-if="files.length"
      class="z-input-file__list"
    >
      <li
        class="z-input-file__item"
        v-for="(file, index) in files"
        :key="index"
      >
        <p class="z-input-file__item-name">
          <span>{{ file.name }}</span>
          <span
            class="z-input-file__item-clear"
            @click="remove(file)"
          ></span>
        </p>
        <p class="z-input-file__item-info">
          <template v-if="file.type">{{ file.type }}</template>
          <template v-if="file.size">({{ getFormatedSize(file.size) }})</template>
        </p>
      </li>
    </ul>
    <span
      :class="[
        'z-input-file__error',
        errorClass
      ]"
      v-html="error"
      v-if="error && !isValid"
    ></span>
  </div>
</template>

<script>
export default {
    name: 'z-input-file',
    props: {
        name: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        accept: String,
        placeholder: String,
        errorClass: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            files: [],
            uploaded: [],
            isValid: null,
            error: '',
            text: {
                label: {
                    ru: 'Прикрепить файлы',
                    en: 'Attach files'
                },
                errors: {
                    required: {
                        ru: 'Поле обязательно для заполнения',
                        en: 'Required field',
                        cn: '填项目'
                    }
                }
            }
        }
    },
    computed: {
        labelText () {
            if (this.placeholder) return this.placeholder
            return localize(this.text.label)
        }
    },
    methods: {
        onChange (target) {
            this.updateList(target.files)
            this.uploaded = target.files
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        clear () {
            this.files = []
            this.uploaded = []
            this.$refs.file.value = null
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        updateList (data) {
            this.files = Array.from(data).map(item => {
                const re = /(?:\.([^.]+))?$/

                return {
                    name: item.name,
                    size: item.size,
                    type: re.exec(item.name)[1]
                }
            })
        },
        remove (file) {
            let dt = new DataTransfer()
            Array.from(this.$refs.file.files).forEach(item => {
                if (file.name !== item.name) {
                    dt.items.add(item)
                }
            })

            if (dt.files.length === 0) {
                this.clear()
                return
            }

            this.$refs.file.files = dt.files
            this.uploaded = dt.files
            this.updateList(this.$refs.file.files)
            this.$nextTick(() => this.$emit('change', this.uploaded))
            this.validate()
        },
        getFormatedSize (size) {
            let result = size / 1024
            if (result >= 1024) {
                return `${(result / 1024).toFixed(1)} Mb`
            }

            return `${result.toFixed(1)} Kb`
        },
        validate () {
            if (this.required && this.uploaded.length === 0) {
                this.isValid = false
                this.error = localize(this.text.errors.required)
                return
            }

            this.isValid = true
            this.error = ''
        }
    }
}

</script>

<style lang="scss">
$errorColor: #CA3D21;

.z-input-file {
  $parent: &;

  &__icon {
    width: 12px;
    height: 23px;
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='23' viewBox='0 0 12 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0428 7.05681C11.0428 6.72544 10.7742 6.45681 10.4428 6.45681C10.1115 6.45681 9.84285 6.72544 9.84285 7.05681L9.84285 17.5568C9.84285 18.5912 9.43195 19.5831 8.70056 20.3145C7.96917 21.0459 6.97719 21.4568 5.94285 21.4568C4.9085 21.4568 3.91652 21.0459 3.18513 20.3145C2.45374 19.5831 2.04284 18.5912 2.04284 17.5568L2.04285 3.86986C2.04285 2.59911 3.10319 1.54363 4.44285 1.54363C5.7825 1.54363 6.84285 2.59911 6.84285 3.86986L6.84285 17.3699C6.84285 17.6085 6.74803 17.8375 6.57925 18.0062C6.41047 18.175 6.18155 18.2698 5.94286 18.2698C5.70417 18.2698 5.47525 18.175 5.30648 18.0062C5.1377 17.8375 5.04288 17.6085 5.04288 17.3699L5.04288 6.86986C5.04288 6.53849 4.77425 6.26986 4.44288 6.26986C4.11151 6.26986 3.84288 6.53849 3.84288 6.86986L3.84288 17.3699C3.84288 17.9268 4.06412 18.4609 4.45795 18.8548C4.85177 19.2486 5.38591 19.4698 5.94286 19.4698C6.49981 19.4698 7.03395 19.2486 7.42777 18.8548C7.8216 18.4609 8.04285 17.9268 8.04285 17.3699L8.04285 3.86986C8.04285 1.90838 6.4169 0.343628 4.44285 0.343628C2.46879 0.343628 0.842846 1.90838 0.842846 3.86986L0.842845 17.5568C0.842845 18.9094 1.38016 20.2066 2.3366 21.1631C3.29304 22.1195 4.59024 22.6568 5.94285 22.6568C7.29545 22.6568 8.59265 22.1195 9.54909 21.1631C10.5055 20.2066 11.0428 18.9094 11.0428 17.5568L11.0428 7.05681Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    margin-right: 8px;
  }

  &__input {
    display: none;
  }

  &__label {
    display: flex;
    align-items: center;
    color: #0077C8;
    font-size: 18px;
    transition: color 0.2s ease-in;
    cursor: pointer;

    &:hover {
      color: #4B5368;
    }

    &:active {
      color: $token-colors-gray-60;
    }
  }

  &__list {
    margin-top: 16px;
  }

  &__item {
    position: relative;
    padding-left: 40px;

    & + & {
      margin-top: 16px;
    }

    &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.00004 2.16669C7.16019 2.16669 6.35473 2.50032 5.76087 3.09418C5.167 3.68805 4.83337 4.4935 4.83337 5.33335V26.6667C4.83337 27.5065 5.167 28.312 5.76087 28.9059C6.35473 29.4997 7.16019 29.8334 8.00004 29.8334H24C24.8399 29.8334 25.6453 29.4997 26.2392 28.9059C26.8331 28.312 27.1667 27.5065 27.1667 26.6667V10.6667C27.1667 10.5341 27.114 10.4069 27.0203 10.3131L19.0203 2.31313C18.9265 2.21937 18.7993 2.16669 18.6667 2.16669H8.00004ZM6.46798 3.80129C6.8743 3.39496 7.4254 3.16669 8.00004 3.16669H18.1667V10.6667C18.1667 10.9428 18.3906 11.1667 18.6667 11.1667H26.1667V26.6667C26.1667 27.2413 25.9384 27.7924 25.5321 28.1988C25.1258 28.6051 24.5747 28.8334 24 28.8334H8.00004C7.42541 28.8334 6.8743 28.6051 6.46798 28.1988C6.06165 27.7924 5.83337 27.2413 5.83337 26.6667V5.33335C5.83337 4.75872 6.06165 4.20762 6.46798 3.80129ZM25.4596 10.1667L19.1667 3.87379V10.1667H25.4596Z' fill='%23858DA6'/%3E%3Cpath d='M9.23257 21.3333V19.0923H10.8166C12.0676 19.0923 12.7786 18.2283 12.7786 17.2113C12.7786 16.1943 12.0766 15.3303 10.8166 15.3303H8.17957V21.3333H9.23257ZM10.6726 18.1653H9.23257V16.2573H10.6726C11.2666 16.2573 11.6986 16.6353 11.6986 17.2113C11.6986 17.7873 11.2666 18.1653 10.6726 18.1653ZM15.8872 21.3333C17.7592 21.3333 19.0282 20.1003 19.0282 18.3363C19.0282 16.5723 17.7592 15.3303 15.8872 15.3303H13.6552V21.3333H15.8872ZM15.8872 20.4063H14.7082V16.2573H15.8872C17.2282 16.2573 17.9482 17.1753 17.9482 18.3363C17.9482 19.4703 17.1922 20.4063 15.8872 20.4063ZM21.0978 21.3333V18.7413H24.0948V17.8143H21.0978V16.2573H24.1578V15.3303H20.0448V21.3333H21.0978Z' fill='%23737B91'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        width: 32px;
        height: 32px;
        display: block;
        position: absolute;
        top: 0.2em;
        left: 0;
    }
  }

  &__item-clear {
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.4243 6.42429C18.6586 6.18997 18.6586 5.81007 18.4243 5.57576C18.19 5.34145 17.8101 5.34145 17.5758 5.57576L12 11.1515L6.42429 5.57576C6.18997 5.34145 5.81007 5.34145 5.57576 5.57576C5.34145 5.81007 5.34145 6.18997 5.57576 6.42429L11.1515 12L5.57576 17.5758C5.34145 17.8101 5.34145 18.19 5.57576 18.4243C5.81007 18.6586 6.18997 18.6586 6.42429 18.4243L12 12.8486L17.5758 18.4243C17.8101 18.6586 18.19 18.6586 18.4243 18.4243C18.6586 18.19 18.6586 17.8101 18.4243 17.5758L12.8486 12L18.4243 6.42429Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
    width: 24px;
    height: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 4px;
    flex-shrink: 0;
  }

  &__item-name {
    display: flex;
    font-size: 16px;
    color: $token-colors-gray-60;
    line-height: 140%;
    margin-bottom: 0;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__item-info {
    font-size: 13px;
    color: $token-colors-gray-30;
    line-height: 1;
    margin-top: 2px;
    text-transform: uppercase;
  }

  &__error {
    font-size: 0.8125em;
    color: $errorColor;
    position: relative;
    left: 20px;
  }

  // disabled state
  &.is-disabled {
    #{$parent}__label {
      color: #BFBFBF;
    }
  }

  // errored state
  &.is-errored {
    #{$parent}__label {
      color: $errorColor;
    }
  }
}
</style>
