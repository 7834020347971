<template>
    <form @submit.prevent="sendFormData" class="illiquids-request-form">
        <h5 class="illiquids-request-form__title">Уважаемые Клиенты!</h5>
        <p class="illiquids-request-form__text">
            Енисейское пароходство предлагает Вам сервис для удобного оформления заявки на приобретение неликвидных материалов (МПЗ). 
            Заполните предлагаемый электронный бланк и нажмите кнопку «Отправить». Срок рассмотрения заявки до 3-х рабочих дней. 
            После обработки Вашей заявки менеджеры компании свяжутся с Вами.
        </p>

        <z-input
            v-model="formData['material-name']"
            type="text"
            name="material-name"
            ref="material-name"
            :title="text.materialName.title"
            :placeholder="text.materialName.placeholder"
            required
            class="illiquids-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['brand-engine']"
            type="text"
            name="brand-engine"
            ref="brand-engine"
            :title="text.brandEngine.title"
            :placeholder="text.brandEngine.placeholder"
            class="illiquids-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['number']"
            type="number"
            name="number"
            ref="number"
            validateOnBlur
            :title="text.number.title"
            :placeholder="text.number.placeholder"
            required
            class="illiquids-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['amount']"
            type="text"
            name="amount"
            ref="amount"
            :title="text.amount.title"
            :placeholder="text.amount.placeholder"
            required
            class="illiquids-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['price']"
            type="text"
            name="price"
            ref="price"
            :title="text.price.title"
            :placeholder="text.price.placeholder"
            class="illiquids-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['organization-name']"
            type="text"
            name="organization-name"
            ref="organization-name"
            :title="text.organizationName.title"
            :placeholder="text.organizationName.placeholder"
            required
            class="illiquids-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['fio']"
            type="text"
            name="fio"
            ref="fio"
            :title="text.fio.title"
            :placeholder="text.fio.placeholder"
            required
            class="illiquids-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['contact-info']"
            type="text"
            name="contact-info"
            ref="contact-info"
            :title="text.contactInfo.title"
            :placeholder="text.contactInfo.placeholder"
            required
            class="illiquids-request-form__input"
        ></z-input>

        <z-textarea
            v-model="formData['additional-info']"
            name="additional-info"
            ref="additional-info"
            :title="text.additionalInfo.title"
            :placeholder="formData['additional-info'] ? '' : text.additionalInfo.placeholder"
            class="illiquids-request-form__input illiquids-request-form__input--textarea"
        ></z-textarea>

        <p class="illiquids-request-form__captcha-title">{{ text.captcha }}</p>
        <div class="illiquids-request-form__captcha-wrapper">
            <div class="illiquids-request-form__captcha-container">
                <img
                    v-if="isUpdatedCapcha"
                    :src="'/api/captcha/?' + capchaSalt"
                    width="100"
                    height="50"
                    alt=""
                    class="illiquids-request-form__captcha-img"
                />
                <div v-else class="illiquids-request-form__captcha-img"></div>
            </div>
        
            <z-button
                type="button"
                kind="secondary"
                rounded
                @click="updateCapcha"
                class="illiquids-request-form__captcha-button"
            >
                <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 24px; height: 24px;">
                    <path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.285 4.184l-3.477 3.477-.707-.707 2.552-2.552A7.613 7.613 0 005.02 15.75a.5.5 0 01-.866.5 8.613 8.613 0 018.653-12.836L10.101.707 10.808 0l3.477 3.477.353.354-.353.353zm3.921 3.953a.5.5 0 11.866-.5 8.612 8.612 0 01-8.536 12.852.498.498 0 01-.14-.039l2.73 2.73-.707.708-3.477-3.478-.354-.353.354-.354 3.477-3.477.707.707-2.561 2.561a.506.506 0 01.096.003 7.613 7.613 0 007.545-11.36zM10.1 19.96l-.098.098.14.14a.497.497 0 01-.042-.238z" fill="#0077C8"></path>
                </svg>
            </z-button>

            <z-input
                v-model="captcha"
                type="text"
                name="captcha"
                ref="captcha"
                required
                class="illiquids-request-form__captcha-input"
            ></z-input>
        </div>

        <z-checkbox
            v-model="isChecked"
            name="policy"
            ref="policy"
            :data="[{ value: '1' }]"
            required
            class="illiquids-request-form__checkbox"
        >
            <template #text>
                <p>
                    Я ознакомился с <a href="/terms/" target="_blank" class="link">Политикой о защите персональных данных</a> 
                    и даю свое согласие на обработку моих персональных данных.
                </p>
            </template>
        </z-checkbox>

        <z-button class="illiquids-request-form__button">{{ text.button }}</z-button>

        <h5 class="illiquids-request-form__note-title">Примечание:</h5>
        <p class="illiquids-request-form__note-text">
            Заявки на реализацию неликвидных материалов (МПЗ) принимаются также в письменном виде на имя Генерального директора. 
            Бланк письменной заявки можно скачать 
            <a 
                href="/upload/Non_liquid_zajavka.xls" 
                title="Заявка на приобретение неликвидных материально-производственных запасов - файл MS-Excel (18 КБ)" 
                class="link"
            >
            отсюда
            </a>
        </p>
        <p class="illiquids-request-form__note-text">
            Запросы в печатном виде принимаются по факсу +7(391) 259-14-88, почтой по адресу: 660049, г. Красноярск, ул. Бограда, 15. 
            Канцелярия АО «ЕРП» или на электронный адрес: <a href="mailto:erp@e-river.ru" class="link">erp@e-river.ru</a>
        </p>
        
    </form>
</template>

<script>
import ZButton from "../app/ZButton"
import ZCheckbox from "../app/ZCheckbox"
import ZInput from "../app/ZInput"
import ZTextarea from "../app/ZTextarea"
import { text } from "./constans.js"
import { sendIlliquidsForm } from '../../api/illiquids-request-form.js'
import { sendCaptcha } from '../../api/send-captcha.js'

export default {
    name: 'illiquids-request-form',
    components: {
        ZButton,
        ZCheckbox,
        ZInput,
        ZTextarea
    },
    data() {
        return {
            isChecked: [],
            isLoading: false,
            formData: {
                'material-name': '',
                'brand-engine': '',
                'number': '',
                'amount': '',
                'price': '',
                'organization-name': '',
                'fio': '',
                'contact-info': '',
                'additional-info': '',
            },
            text,
            captcha: '',
            capchaSalt: Date.now(),
            isUpdatedCapcha: true,
            deleteSelected: false,
        };
    },
    methods: {
        validateInputFormat() {
            if (this.formData['number']) {
                const requiredFields = ['number'];
                let isValid = true;

                requiredFields.forEach((item) => {
                    const field = this.$refs[item];
                    if (field) {
                        field.validate();
                        if (field.error) isValid = false;
                    }
                });
                return isValid;
            }

            return true;
        },

        validateRequiredInputs() {
            const requiredFields = [
                'material-name', 
                'number', 
                'amount', 
                'organization-name', 
                'fio',
                'contactInfo',
                'captcha', 
                'policy'
            ]
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },

        updateCapcha() {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },

        async sendFormData() {
            if (this.isLoading) return

            const isValidRequiredInputs = this.validateRequiredInputs()
        
            if (isValidRequiredInputs) {
                const isValidInputFormat = this.validateInputFormat()

                if (!isValidInputFormat) {
                    showNotyfications(this.text.notificationValidateError, { type: 'error' })
                    return
                }

                try {
                    this.isLoading = true
                    
                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }

                    const captchaResponse = await sendCaptcha(this.captcha)

                    if (captchaResponse.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await sendIlliquidsForm(data)

                        if (formResponse.status === 'success') {
                            showNotyfications(formResponse.message, { type: 'success' })

                            this.formData['material-name'] = ''
                            this.formData['brand-engine'] = ''
                            this.formData['number'] = ''
                            this.formData['amount'] = ''
                            this.formData['price'] = ''
                            this.formData['organization-name'] = ''
                            this.formData['fio'] = ''
                            this.formData['contact-info'] = ''
                            this.formData['additional-info'] = ''
                            this.deleteSelected = true
                            this.isChecked = []
                        } else {
                            showNotyfications(formResponse.message, { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        },
    }
};
</script>

<style lang="scss" src="./illiquids-request-form.scss"></style>