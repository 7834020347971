<template>
    <div class="tender-card">
        <div v-if="item.dateCreate.timestamp" class="tender-card__header">
            <span class="tender-card__text-label">Дата публикации:</span>
            <span class="tender-card__text" v-html="formatDate(item.dateCreate.timestamp)"></span>
        </div>
        <div class="tender-card__body">
            <h1 class="tender-card__body-title" v-html="item.description.value"></h1>
            <div
                v-if="item.file && item.file.length"
                v-for="(file, idx) in item.file"
                :key="idx"
                class="tender-card__body-file"
            >
                <a :href="file.link" :class="getFileLinkClass(file.type)" target="_blank">
                    <span>
                        Скачать документацию
                    </span>
                    <span class="file-list__size">{{ file.type }} &#x2022; {{ file.size }}</span>
                </a>
            </div>

            <div class="tender-card__body-content">
                <div v-if="item.number.value" class="tender-card__body-item">
                    <span class="tender-card__text-label">Номер лота:</span>
                    <span class="tender-card__text" v-html="item.number.value"></span>
                </div>

                <div v-if="item.endDate.timestamp" class="tender-card__body-item">
                    <span class="tender-card__text-label">Приём заявок до:</span>
                    <span
                        class="tender-card__text"
                        v-html="formatDate(item.endDate.timestamp)"
                    ></span>
                </div>

                <div v-if="item.contact.value" class="tender-card__body-item">
                    <span class="tender-card__text-label">Контактное лицо:</span>
                    <div class="tender-card__text" v-html="item.contact.value"></div>
                </div>
            </div>
            <div v-if="item.text.value" class="tender-card__body-extra">
                <span class="tender-card__text-label">О подаче заявки:</span>
                <span class="tender-card__text" v-html="item.text.value"></span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { momentFilter } from '../../utils/filter.js'

export default {
    name: 'tender-card',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    created() {
        moment.locale('ru')
    },
    methods: {
        formatDate(date) {
            return momentFilter(date, 'DD.MM.YYYY')
        },
        getFileLinkClass(type) {
            return `link file-list__item file-list__item--${type}`
        }
    }
}
</script>

<style scoped lang="scss">
.tender-card {
    display: flex;
    flex-direction: column;
    padding: 32px 0;

    @include breakpoint(tablet) {
        padding: 30px 0;
    }

    & > * {
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        align-items: center;

        & .tender-card__text-label {
            margin-bottom: 0;
            margin-right: 8px;
        }
    }

    &__text {
        color: $token-colors-black;
        font-size: 14px;
        line-height: 1.4;
    }

    &__text-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.1;
        margin-bottom: 6px;
        color: $token-colors-black-40;
    }

    &__body-title {
        width: 100%;
        max-width: 1200px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;
        color: $token-colors-black-90;
        margin-bottom: 18px;
    }

    &__body-file {
        margin-bottom: 24px;

        & .file-list__item {
            display: flex;
            flex-direction: column;
            width: fit-content;
            padding-left: 50px !important;
            margin-left: -6px;
            line-height: 1.4 !important;
            transition: opacity 0.25s, color 0.25s ease-in-out;
        }

        & .file-list__size {
            text-transform: uppercase;
        }
    }

    &__body-content {
        display: flex;
        margin-bottom: 18px;

        @include breakpoint(tablet) {
            flex-direction: column;
            margin-bottom: 0;
        }
    }

    &__body-item {
        display: flex;
        flex-direction: column;
        flex: 1;

        @include breakpoint(tablet) {
            margin-bottom: 18px;
        }
    }

    &__body-extra {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
</style>
