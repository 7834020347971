<template>
    <figure class="media-library-card">
        <a :href="item.link" target="_blank" class="tile u-underline--no u-entire-height">
            <div class="tile__img" :style="`background-image: url(${item.image})`"></div>
            <figcaption class="tile__content u-medium text-size-xl" v-html="item.name"></figcaption>
        </a>
    </figure>
</template>

<script>
export default {
    name: 'MediaLibraryCard',
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.media-library-card {
    & .tile__img {
        position: relative;
        overflow: hidden;

        &::before {
            content: 'Перейти к галерее';
            display: flex;
            align-items: center;
            justify-content: center;
            color: $token-colors-white;
            @include typo-level(M);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($token-colors-black, 0.2);
            opacity: 0;
            transition: opacity 0.3s ease;
        }
    }

    &:hover {
        & .tile__img::before {
            opacity: 1;
        }
    }
}
</style>
