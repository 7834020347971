<template>
    <div class="projects-page">
        <p>
            Почти 90&nbsp;лет Енисейское речное пароходство возит грузы по&nbsp;Енисею и&nbsp;его притокам. 
            Пароходство твердо стоит на&nbsp;ногах, работая в&nbsp;ритме краевой экономики. 
            Енисейским пароходством сегодня активно изыскиваются и&nbsp;осваиваются новые грузопотоки. 
            Реализация любого, значимого для экономики Таймыра, Эвенкии, Красноярского края, проекта не&nbsp;обходится без участия речников.
        </p>

        <p>
            ЕРП является основным транспортным партнером в&nbsp;крупнейших инвестиционных проектах Сибири: 
            программе освоения Нижнего Приангарья, развития Ванкорского нефтегазового месторождения, 
            освоения Юрубчено-Тахомского месторождения, строительство Богучанской ГЭС, 
            развития Норильского промышленного района, строительство нефтепровода Куюмба-Тайшет и&nbsp;др.
        </p>

        <div class="projects-page__cards-list" ref="container" v-if="items && items.length">
            <results :data="items" />
        </div>
        <z-preloader v-if="isLoading" />
        <z-pagination
            v-if="paginationData"
            class="u-top-margin--3xl-important"
            show-next
            show-prev
            :data="paginationData"
            @change-page="paginationHandler"
        >
        </z-pagination>
    </div>
</template>

<script>
import ZPreloader from '../app/ZPreloader/ZPreloader.vue'
import ZPagination from '../app/ZPagination/ZPagination.vue'
import Results from './components/Results.vue';
import { throttle } from 'throttle-debounce'
const qs = require('query-string')

export default {
    name: 'projects-page',
    components: {
        Results,
        ZPreloader,
        ZPagination
    },
    data () {
        return {
            params: {
                page: 1
            },
            isLoading: false,
            items: this.$root.app.components?.projects?.items || [],
            nav: this.$root.app.components?.projects?.nav || {}
        }
    },
    mounted () {
        $('.js-select')
            .select2({
                theme: 'filled',
                minimumResultsForSearch: Infinity
            })
            .on('select2:select', this.filterHandler);
    },
    methods: {
        filterHandler(e) {
            let data = e.params.data;
            this.params.page = 1
            this.send()
        },
        paginationHandler (data) {
            this.params.page = data.page
            this.send()
        },
        send: throttle(500, function () {
            this.isLoading = true

            let queryParams = qs.stringify(this.params)

            fetch(`/api/projects/?${queryParams}`)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        let error = new Error(response.statusText);
                        error.response = response;
                        throw error;
                    };
                })
                .then(response => {
                    this.items = response.items
                    this.nav = response.nav
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false
                })
        })
    },
    computed: {
        paginationData () {
            if (this.nav) {
                return ({
                    currentPage: this.nav?.current,
                    totalItems: this.nav?.count,
                    totalPages: this.nav?.total,
                    pageRange: 1
                })
            }

            return null
        }
    }
}
</script>
