var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "z-input",
        {
          "is-focused": _vm.isFocused,
          "is-errored": _vm.isValid === false,
          "is-valid": _vm.isValid,
          "is-filled":
            _vm.inputValue !== "" && typeof _vm.inputValue !== "object",
          "is-disabled": _vm.disabled
        }
      ]
    },
    [
      _c("label", { staticClass: "z-input__label" }, [
        _c("div", { staticClass: "z-input__title-container" }, [
          _vm.title
            ? _c("p", {
                staticClass: "z-input__title",
                domProps: { innerHTML: _vm._s(_vm.title) }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "z-input__container" }, [
          _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.mask,
                    expression: "mask"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputValue,
                    expression: "inputValue"
                  }
                ],
                staticClass: "z-input__input",
                attrs: { name: "name", type: "text", disabled: _vm.disabled },
                domProps: { value: _vm.inputValue },
                on: {
                  blur: _vm.onBlur,
                  focus: _vm.onFocus,
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputValue = $event.target.value
                    },
                    function($event) {
                      return _vm.onInput($event)
                    }
                  ],
                  change: function($event) {
                    return _vm.onChange($event)
                  },
                  keypress: [
                    _vm.onKeyPress,
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onPressEnter.apply(null, arguments)
                    }
                  ]
                }
              },
              "input",
              _vm.$attrs,
              false
            )
          ),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.placeholder,
                expression: "placeholder"
              }
            ],
            staticClass: "z-input__placeholder",
            domProps: { innerHTML: _vm._s(_vm.placeholder) }
          }),
          _vm._v(" "),
          _vm.isShowClearIcon
            ? _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.onClear.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass:
                        "z-input__icon z-input__icon--clear z-icon z-fill z-top",
                      staticStyle: { width: "24px", height: "24px" },
                      attrs: { version: "1.1", viewBox: "0 0 24 24" }
                    },
                    [
                      _c(
                        "g",
                        { attrs: { "clip-path": "url(#clip0_2646_22105)" } },
                        [
                          _c("path", {
                            attrs: {
                              pid: "0",
                              "fill-rule": "evenodd",
                              "clip-rule": "evenodd",
                              d:
                                "M18.424 6.425a.6.6 0 10-.848-.849L12 11.152 6.424 5.576a.6.6 0 10-.848.849L11.15 12l-5.575 5.576a.6.6 0 00.848.849L12 12.849l5.576 5.576a.6.6 0 00.848-.849L12.848 12l5.576-5.575z",
                              fill: "#0077C8"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("defs", [
                        _c("clipPath", { attrs: { id: "clip0_2646_22105" } }, [
                          _c("path", {
                            attrs: {
                              pid: "1",
                              fill: "#fff",
                              d: "M0 0h24v24H0z"
                            }
                          })
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.error && !_vm.isValid
        ? _c("span", {
            class: ["z-input__error", _vm.errorClass],
            domProps: { innerHTML: _vm._s(_vm.error) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ba05e6f2", { render: render, staticRenderFns: staticRenderFns })
  }
}