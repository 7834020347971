var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "freight-request-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.sendFormData.apply(null, arguments)
        }
      }
    },
    [
      _c("h5", { staticClass: "freight-request-form__title" }, [
        _vm._v("Уважаемые Клиенты!")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "freight-request-form__text" }, [
        _vm._v(
          "\n        Енисейское пароходство предлагает Вам сервис для удобного оформления запросов на перевозку грузов нашей компанией. \n        Заполните предлагаемый электронный бланк и нажмите кнопку «Отправить». \n        После обработки Вашей заявки менеджеры компании свяжутся с Вами.\n    "
        )
      ]),
      _vm._v(" "),
      _c("z-input", {
        ref: "point-of-departure",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "text",
          name: "point-of-departure",
          title: _vm.text.pointOfDeparture.title,
          placeholder: _vm.text.pointOfDeparture.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["point-of-departure"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "point-of-departure", $$v)
          },
          expression: "formData['point-of-departure']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "destination",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "text",
          name: "destination",
          title: _vm.text.destination.title,
          placeholder: _vm.text.destination.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["destination"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "destination", $$v)
          },
          expression: "formData['destination']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "departure-period",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "text",
          name: "departure-period",
          title: _vm.text.departurePeriod.title,
          placeholder: _vm.text.departurePeriod.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["departure-period"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "departure-period", $$v)
          },
          expression: "formData['departure-period']"
        }
      }),
      _vm._v(" "),
      _c("h5", { staticClass: "freight-request-form__table-title" }, [
        _vm._v("Характеристики груза")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "freight-request-form__table" }, [
        _c("div", { staticClass: "freight-request-form__table-wrapper" }, [
          _c("div", { staticClass: "freight-request-form__table-item" }, [
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--title" },
              [_c("span", [_vm._v(_vm._s(_vm.text.shippingName.title))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--body" },
              [
                _c("z-input", {
                  ref: "shipping-name",
                  staticClass: "freight-request-form__input",
                  attrs: {
                    type: "text",
                    name: "shipping-name",
                    clearable: false,
                    required: ""
                  },
                  model: {
                    value: _vm.formData["shipping-name"],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "shipping-name", $$v)
                    },
                    expression: "formData['shipping-name']"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "freight-request-form__table-item" }, [
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--title" },
              [_c("span", [_vm._v(_vm._s(_vm.text.receiptOfCargo.title))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--body" },
              [
                _c("z-input", {
                  ref: "receipt-of-cargo",
                  staticClass: "freight-request-form__input",
                  attrs: {
                    type: "text",
                    name: "receipt-of-cargo",
                    clearable: false,
                    required: ""
                  },
                  model: {
                    value: _vm.formData["receipt-of-cargo"],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "receipt-of-cargo", $$v)
                    },
                    expression: "formData['receipt-of-cargo']"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "freight-request-form__table-item" }, [
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--title" },
              [_c("span", [_vm._v(_vm._s(_vm.text.specifications.title))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--body" },
              [
                _c("z-input", {
                  ref: "specifications",
                  staticClass: "freight-request-form__input",
                  attrs: {
                    type: "text",
                    name: "specifications",
                    clearable: false,
                    required: ""
                  },
                  model: {
                    value: _vm.formData["specifications"],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "specifications", $$v)
                    },
                    expression: "formData['specifications']"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "freight-request-form__table-item" }, [
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--title" },
              [_c("span", [_vm._v(_vm._s(_vm.text.typeOfPackaging.title))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--body" },
              [
                _c("z-input", {
                  ref: "type-of-packaging",
                  staticClass: "freight-request-form__input",
                  attrs: {
                    type: "text",
                    name: "type-of-packaging",
                    clearable: false,
                    required: ""
                  },
                  model: {
                    value: _vm.formData["type-of-packaging"],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "type-of-packaging", $$v)
                    },
                    expression: "formData['type-of-packaging']"
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "freight-request-form__table-wrapper" }, [
          _c("div", { staticClass: "freight-request-form__table-item" }, [
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--title" },
              [_c("span", [_vm._v(_vm._s(_vm.text.numberOfCargo.title))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--body" },
              [
                _c("z-input", {
                  ref: "number-of-cargo",
                  staticClass: "freight-request-form__input",
                  attrs: {
                    type: "number",
                    name: "number-of-cargo",
                    clearable: false,
                    required: ""
                  },
                  model: {
                    value: _vm.formData["number-of-cargo"],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "number-of-cargo", $$v)
                    },
                    expression: "formData['number-of-cargo']"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "freight-request-form__table-item" }, [
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--title" },
              [_c("span", [_vm._v(_vm._s(_vm.text.weightItem.title))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "freight-request-form__table-item--body" },
              [
                _c("z-input", {
                  ref: "weight-item",
                  staticClass: "freight-request-form__input",
                  attrs: {
                    type: "number",
                    name: "weight-item",
                    clearable: false,
                    required: ""
                  },
                  model: {
                    value: _vm.formData["weight-item"],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "weight-item", $$v)
                    },
                    expression: "formData['weight-item']"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "freight-request-form__table-item freight-request-form__table-item--nesting"
            },
            [
              _c(
                "div",
                { staticClass: "freight-request-form__table-item--title" },
                [_c("span", [_vm._v(_vm._s(_vm.text.dimensions.title))])]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "freight-request-form__table-body--nesting" },
                [
                  _c(
                    "div",
                    { staticClass: "freight-request-form__table-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "freight-request-form__table-item--title"
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.text.dimensions.fields["length"]))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "freight-request-form__table-item--body"
                        },
                        [
                          _c("z-input", {
                            ref: "dimensions-length",
                            staticClass: "freight-request-form__input",
                            attrs: {
                              type: "number",
                              name: "dimensions-length",
                              clearable: false,
                              required: ""
                            },
                            model: {
                              value: _vm.formData["dimensions-length"],
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "dimensions-length", $$v)
                              },
                              expression: "formData['dimensions-length']"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "freight-request-form__table-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "freight-request-form__table-item--title"
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.text.dimensions.fields.width))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "freight-request-form__table-item--body"
                        },
                        [
                          _c("z-input", {
                            ref: "dimensions-width",
                            staticClass: "freight-request-form__input",
                            attrs: {
                              type: "number",
                              name: "dimensions-width",
                              clearable: false,
                              required: ""
                            },
                            model: {
                              value: _vm.formData["dimensions-width"],
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "dimensions-width", $$v)
                              },
                              expression: "formData['dimensions-width']"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "freight-request-form__table-item" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "freight-request-form__table-item--title"
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.text.dimensions.fields.height))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "freight-request-form__table-item--body"
                        },
                        [
                          _c("z-input", {
                            ref: "dimensions-height",
                            staticClass: "freight-request-form__input",
                            attrs: {
                              type: "number",
                              name: "dimensions-height",
                              clearable: false,
                              required: ""
                            },
                            model: {
                              value: _vm.formData["dimensions-height"],
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "dimensions-height", $$v)
                              },
                              expression: "formData['dimensions-height']"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "freight-request-form__input--file" },
        [
          _c("z-input-file", {
            ref: "fileInput",
            attrs: {
              name: "file",
              placeholder: _vm.text.file.placeholder,
              accept: ".xlsx, .docx, image/png, image/jpeg"
            },
            on: { change: _vm.onChageFile }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("z-input", {
        ref: "organization-name",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "text",
          name: "organization-name",
          title: _vm.text.organizationName.title,
          placeholder: _vm.text.organizationName.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["organization-name"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "organization-name", $$v)
          },
          expression: "formData['organization-name']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "position-supervisor",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "text",
          name: "position-supervisor",
          title: _vm.text.positionSupervisor.title,
          required: ""
        },
        model: {
          value: _vm.formData["position-supervisor"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "position-supervisor", $$v)
          },
          expression: "formData['position-supervisor']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "fio-supervisor",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "text",
          name: "fio-supervisor",
          title: _vm.text.fioSupervisor.title,
          required: ""
        },
        model: {
          value: _vm.formData["fio-supervisor"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "fio-supervisor", $$v)
          },
          expression: "formData['fio-supervisor']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "fio-contact",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "text",
          name: "fio-contact",
          title: _vm.text.fioContact.title,
          placeholder: _vm.text.fioContact.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["fio-contact"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "fio-contact", $$v)
          },
          expression: "formData['fio-contact']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "phone-contact",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "tel",
          mask: "+7(###)###-##-##",
          name: "phone-contact",
          title: _vm.text.phoneContact.title,
          required: ""
        },
        model: {
          value: _vm.formData["phone-contact"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "phone-contact", $$v)
          },
          expression: "formData['phone-contact']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "email-contact",
        staticClass: "freight-request-form__input",
        attrs: {
          type: "email",
          name: "email-contact",
          title: _vm.text.emailContact.title,
          required: ""
        },
        model: {
          value: _vm.formData["email-contact"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "email-contact", $$v)
          },
          expression: "formData['email-contact']"
        }
      }),
      _vm._v(" "),
      _c("z-textarea", {
        ref: "additional-info",
        staticClass:
          "freight-request-form__input freight-request-form__input--textarea",
        attrs: {
          name: "additional-info",
          title: _vm.text.additionalInfo.title,
          placeholder: _vm.formData["additional-info"]
            ? ""
            : _vm.text.additionalInfo.placeholder
        },
        model: {
          value: _vm.formData["additional-info"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "additional-info", $$v)
          },
          expression: "formData['additional-info']"
        }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "freight-request-form__captcha-title" }, [
        _vm._v(_vm._s(_vm.text.captcha))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "freight-request-form__captcha-wrapper" },
        [
          _c(
            "div",
            { staticClass: "freight-request-form__captcha-container" },
            [
              _vm.isUpdatedCapcha
                ? _c("img", {
                    staticClass: "freight-request-form__captcha-img",
                    attrs: {
                      src: "/api/captcha/?" + _vm.capchaSalt,
                      width: "100",
                      height: "50",
                      alt: ""
                    }
                  })
                : _c("div", {
                    staticClass: "freight-request-form__captcha-img"
                  })
            ]
          ),
          _vm._v(" "),
          _c(
            "z-button",
            {
              staticClass: "freight-request-form__captcha-button",
              attrs: { type: "button", kind: "secondary", rounded: "" },
              on: { click: _vm.updateCapcha }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "z-icon z-fill",
                  staticStyle: { width: "24px", height: "24px" },
                  attrs: { version: "1.1", viewBox: "0 0 24 24" }
                },
                [
                  _c("path", {
                    attrs: {
                      pid: "0",
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M14.285 4.184l-3.477 3.477-.707-.707 2.552-2.552A7.613 7.613 0 005.02 15.75a.5.5 0 01-.866.5 8.613 8.613 0 018.653-12.836L10.101.707 10.808 0l3.477 3.477.353.354-.353.353zm3.921 3.953a.5.5 0 11.866-.5 8.612 8.612 0 01-8.536 12.852.498.498 0 01-.14-.039l2.73 2.73-.707.708-3.477-3.478-.354-.353.354-.354 3.477-3.477.707.707-2.561 2.561a.506.506 0 01.096.003 7.613 7.613 0 007.545-11.36zM10.1 19.96l-.098.098.14.14a.497.497 0 01-.042-.238z",
                      fill: "#0077C8"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("z-input", {
            ref: "captcha",
            staticClass: "freight-request-form__captcha-input",
            attrs: { type: "text", name: "captcha", required: "" },
            model: {
              value: _vm.captcha,
              callback: function($$v) {
                _vm.captcha = $$v
              },
              expression: "captcha"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("z-checkbox", {
        ref: "policy",
        staticClass: "freight-request-form__checkbox",
        attrs: { name: "policy", data: [{ value: "1" }], required: "" },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c("p", [
                  _vm._v("\n                Я ознакомился с "),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      attrs: { href: "/terms/", target: "_blank" }
                    },
                    [_vm._v("Политикой о защите персональных данных")]
                  ),
                  _vm._v(
                    " \n                и даю свое согласие на обработку моих персональных данных.\n            "
                  )
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isChecked,
          callback: function($$v) {
            _vm.isChecked = $$v
          },
          expression: "isChecked"
        }
      }),
      _vm._v(" "),
      _c("z-button", { staticClass: "illiquids-request-form__button" }, [
        _vm._v(_vm._s(_vm.text.button))
      ]),
      _vm._v(" "),
      _c("h5", { staticClass: "illiquids-request-form__note-title" }, [
        _vm._v("Примечание:")
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", { staticClass: "illiquids-request-form__note-text" }, [
        _vm._v(
          "\n            Запросы в печатном виде принимаются по факсу +7(391)266-03-66 или почтой по адресу: 660049, г. Красноярск, ул. Бограда, 15. Канцелярия АО «ЕРП».\n        "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "illiquids-request-form__note-text" }, [
      _vm._v(
        "\n        Заявки на перевозку грузов принимаются также в письменном виде на фирменных бланках заявителя на имя Генерального директора. \n        Бланк письменной заявки можно скачать \n        "
      ),
      _c(
        "a",
        {
          staticClass: "link",
          attrs: {
            href: "/upload/forma_zayavki_na_perevozku_202303.docx",
            title: "Заявка на перевозку грузов - файл MS-Word (43 КБ)"
          }
        },
        [_vm._v("\n        здесь\n        ")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1152548e", { render: render, staticRenderFns: staticRenderFns })
  }
}