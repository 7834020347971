import Vue from 'vue';

const container = document.getElementById('newspaper-page');
const exists = typeof (container) != 'undefined' && container != null;

import NewspaperPage from './NewspaperPage.vue';
import NewspaperPageItem from './components/NewspaperPageItem.vue';

if (exists) {
    new Vue({
        el: '#newspaper-page',
        components: {
            NewspaperPage,
            NewspaperPageItem
        },
        data: {
            lang: getLang(),
            app: window.App
        },
    });
};