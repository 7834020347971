var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "contact-card",
      class: { "contact-card--horizontal": _vm.horizontal }
    },
    [
      _vm.photo || _vm.hasNameSlot || _vm.hasDescriptionSlot
        ? _c(
            "div",
            { staticClass: "contact-card__head u-bottom-margin--3xs" },
            [
              _vm.photo
                ? _c("picture", { staticClass: "contact-card__pic" }, [
                    _c("img", { attrs: { src: _vm.photo } })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "contact-card__info" }, [
                _vm.hasNameSlot
                  ? _c(
                      "p",
                      { staticClass: "contact-card__name" },
                      [_vm._t("name")],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasDescriptionSlot
                  ? _c(
                      "div",
                      { staticClass: "contact-card__info" },
                      [_vm._t("description")],
                      2
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.horizontal
        ? [
            _c(
              "div",
              { staticClass: "contact-card__wrapper" },
              [
                _vm.address && _vm.address.length
                  ? [
                      _c(
                        "div",
                        {
                          staticClass:
                            "contact-card__row contact-card__row--address"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "contact_card__content" },
                            [
                              _c(
                                "div",
                                { staticClass: "contact-card__title" },
                                [_c("span", [_vm._v(_vm._s(_vm.text.address))])]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.address, function(item, index) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: "address-" + index,
                                      staticClass: "contact-card__link"
                                    },
                                    [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(item.value)
                                        }
                                      })
                                    ]
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.phone && _vm.phone.length
                  ? [
                      _c("div", { staticClass: "contact-card__row" }, [
                        _c(
                          "div",
                          { staticClass: "contact_card__content" },
                          [
                            _c("div", { staticClass: "contact-card__title" }, [
                              _c("span", [_vm._v(_vm._s(_vm.text.phone))])
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.phone, function(item, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: "phone-" + index,
                                    staticClass: "contact-card__link"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "contact-card__link",
                                        attrs: {
                                          href: "tel:" + item.value,
                                          size: "l"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(item.text || item.value) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.fax && _vm.fax.length
                  ? [
                      _c("div", { staticClass: "contact-card__row" }, [
                        _c(
                          "div",
                          { staticClass: "contact_card__content" },
                          [
                            _c("div", { staticClass: "contact-card__title" }, [
                              _c("span", [_vm._v(_vm._s(_vm.text.fax))])
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fax, function(item, index) {
                              return [
                                _c("div", { key: "fax-" + index }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "contact-card__link",
                                      attrs: {
                                        href: "fax:" + item.value,
                                        size: "l"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.text || item.value) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.email && _vm.email.length
                  ? [
                      _c("div", { staticClass: "contact-card__row" }, [
                        _c(
                          "div",
                          { staticClass: "contact_card__content" },
                          [
                            _c("div", { staticClass: "contact-card__title" }, [
                              _c("span", [_vm._v(_vm._s(_vm.text.email))])
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.email, function(item, index) {
                              return [
                                _c("div", { key: "email-" + index }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "contact-card__link contact-card__link-mail",
                                      attrs: {
                                        href: "mailto:" + item.value,
                                        size: "l"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(item.text || item.value) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.site && _vm.site.length
                  ? [
                      _c("div", { staticClass: "contact-card__row" }, [
                        _c(
                          "div",
                          { staticClass: "contact_card__content" },
                          [
                            _c("div", { staticClass: "contact-card__title" }, [
                              _c("span", [_vm._v(_vm._s(_vm.text.site))])
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.site, function(item, index) {
                              return [
                                _c("div", { key: "site-" + index }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "contact-card__link contact-card__link- link--external",
                                      attrs: {
                                        target: "_blank",
                                        href: "" + item.value,
                                        size: "l"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(item.text || item.value) +
                                          "\n                                    "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        : [
            _vm.address
              ? [
                  _vm._l(_vm.address, function(item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: "address-" + index,
                          staticClass: "contact-card__row"
                        },
                        [
                          _c("div", { staticClass: "contact-card__title" }, [
                            !item.text
                              ? _c("span", [_vm._v(_vm._s(_vm.text.address))])
                              : _c("div", {
                                  domProps: { innerHTML: _vm._s(item.text) }
                                })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "contact-card__link" }, [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(item.value) }
                            })
                          ])
                        ]
                      )
                    ]
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.phone
              ? [
                  _vm._l(_vm.phone, function(item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: "phone-" + index,
                          staticClass: "contact-card__row"
                        },
                        [
                          _c("div", { staticClass: "contact-card__title" }, [
                            index === 0
                              ? _c("span", [_vm._v(_vm._s(_vm.text.phone))])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "contact-card__link",
                              attrs: { href: "tel:" + item.value, size: "l" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.text || item.value) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.fax
              ? [
                  _vm._l(_vm.fax, function(item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: "fax-" + index,
                          staticClass: "contact-card__row"
                        },
                        [
                          _c("div", { staticClass: "contact-card__title" }, [
                            index === 0
                              ? _c("span", [_vm._v(_vm._s(_vm.text.fax))])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "contact-card__link",
                              attrs: { href: "fax:" + item.value, size: "l" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.text || item.value) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.email
              ? [
                  _vm._l(_vm.email, function(item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: "email-" + index,
                          staticClass: "contact-card__row"
                        },
                        [
                          _c("div", { staticClass: "contact-card__title" }, [
                            index === 0
                              ? _c("span", [_vm._v(_vm._s(_vm.text.email))])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "contact-card__link contact-card__link-mail",
                              attrs: { href: "mailto:" + item.value, size: "l" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.text || item.value) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.site
              ? [
                  _vm._l(_vm.site, function(item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: "site-" + index,
                          staticClass: "contact-card__row"
                        },
                        [
                          _c("div", { staticClass: "contact-card__title" }, [
                            index === 0
                              ? _c("span", [_vm._v(_vm._s(_vm.text.site))])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "contact-card__link contact-card__link-site link--external",
                              attrs: {
                                target: "_blank",
                                href: "" + item.value,
                                size: "l"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.text || item.value) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  })
                ]
              : _vm._e()
          ],
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0df2ccb0", { render: render, staticRenderFns: staticRenderFns })
  }
}