var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-changer" }, [
    _c(
      "a",
      {
        class: [
          "page-changer__link page-changer__link--prev",
          { "is-disabled": !(_vm.data.prev && _vm.data.prev.url) }
        ],
        attrs: {
          href: _vm.data.prev ? _vm.data.prev.url : "javascript:void(0)"
        }
      },
      [
        _c("span", { staticClass: "page-changer__link-icon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "9",
                height: "65",
                viewBox: "0 0 9 65",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M4.87274 0.176433C4.63697 -0.0593331 4.25472 -0.0593331 4.01895 0.176433L0.176906 4.01847C-0.058861 4.25424 -0.058861 4.6365 0.176906 4.87226C0.412673 5.10803 0.794926 5.10803 1.03069 4.87226L4.44584 1.45711L7.86099 4.87226C8.09676 5.10803 8.47901 5.10803 8.71478 4.87226C8.95055 4.6365 8.95055 4.25424 8.71478 4.01847L4.87274 0.176433ZM5.04956 65L5.04956 0.603326H3.84212L3.84212 65H5.04956Z",
                  fill: "#0077C8"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "page-changer__link-text hide-me--mobile" }, [
          _vm._v(
            "\n            " +
              _vm._s((_vm.data.prev && _vm.data.prev.name) || _vm.text.prev) +
              "\n        "
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-changer__button-wrapper" },
      [
        _vm.data["back-link"]
          ? _c(
              "z-button",
              {
                staticClass: "page-changer__button u-bold",
                attrs: {
                  tag: "a",
                  kind: "secondary",
                  size: "s",
                  href: _vm.data["back-link"]
                }
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.text.backLink) }
                })
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        class: [
          "page-changer__link page-changer__link--next",
          { "is-disabled": !(_vm.data.next && _vm.data.next.url) }
        ],
        attrs: {
          href: _vm.data.next ? _vm.data.next.url : "javascript:void(0)"
        }
      },
      [
        _c("span", { staticClass: "page-changer__link-text hide-me--mobile" }, [
          _vm._v(
            "\n            " +
              _vm._s((_vm.data.next && _vm.data.next.name) || _vm.text.next) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "page-changer__link-icon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "9",
                height: "65",
                viewBox: "0 0 9 65",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M4.87274 0.176433C4.63697 -0.0593331 4.25472 -0.0593331 4.01895 0.176433L0.176906 4.01847C-0.058861 4.25424 -0.058861 4.6365 0.176906 4.87226C0.412673 5.10803 0.794926 5.10803 1.03069 4.87226L4.44584 1.45711L7.86099 4.87226C8.09676 5.10803 8.47901 5.10803 8.71478 4.87226C8.95055 4.6365 8.95055 4.25424 8.71478 4.01847L4.87274 0.176433ZM5.04956 65L5.04956 0.603326H3.84212L3.84212 65H5.04956Z",
                  fill: "#0077C8"
                }
              })
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d12758a2", { render: render, staticRenderFns: staticRenderFns })
  }
}