<template>
    <div class="media-library" v-if="media_library_data && media_library_data.length">
        <h2>Фотографии по теме</h2>
        <div class="row">
            <media-library-card
                v-for="(item, idx) in media_library_data"
                :key="idx"
                :item="item"
                class="col-default-4 col-tablet-6 col-v-tablet-10 col-mobile-12"
            />
        </div>
    </div>
</template>

<script>
import MediaLibraryCard from './components/MediaLibraryCard.vue'

export default {
    name: 'MediaLibrary',
    components: {
        MediaLibraryCard
    },
    data() {
        return {
            media_library_data: this.$root.app.components?.['media-library']?.items.all || null
        }
    }
}
</script>
