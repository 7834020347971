<template>
    <div class="contact-card" :class="{'contact-card--horizontal': horizontal}">
        <div class="contact-card__head u-bottom-margin--3xs" v-if="photo || hasNameSlot || hasDescriptionSlot">
            <picture class="contact-card__pic" v-if="photo">
                <img :src="photo"/>
            </picture>
            <div class="contact-card__info">
                <p class="contact-card__name" v-if="hasNameSlot">
                    <slot name="name"></slot>
                </p>
                <div class="contact-card__info" v-if="hasDescriptionSlot">
                    <slot name="description"></slot>
                </div>
            </div>
        </div>
        <template v-if="horizontal">
            <div class="contact-card__wrapper">
                <template v-if="address && address.length">
                    <div class="contact-card__row contact-card__row--address">
                        <div class="contact_card__content">
                            <div class="contact-card__title">
                                <span>{{ text.address }}</span>
                            </div>
                            <template v-for="(item, index) in address">
                                <div class="contact-card__link" :key="`address-${index}`">
                                    <div v-html="item.value"/>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="phone && phone.length">
                    <div class="contact-card__row">
                        <div class="contact_card__content">
                            <div class="contact-card__title">
                                <span>{{ text.phone }}</span>
                            </div>
                            <template v-for="(item, index) in phone">
                                <div class="contact-card__link" :key="`phone-${index}`">
                                    <a class="contact-card__link" :href="`tel:${item.value}`"
                                            size="l">
                                        {{ item.text || item.value }}
                                    </a>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="fax && fax.length">
                    <div class="contact-card__row">
                        <div class="contact_card__content">
                            <div class="contact-card__title">
                                <span>{{ text.fax }}</span>
                            </div>
                            <template v-for="(item, index) in fax">
                                <div :key="`fax-${index}`">
                                    <a class="contact-card__link" :href="`fax:${item.value}`"
                                            size="l">
                                        {{ item.text || item.value }}
                                    </a>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="email && email.length">
                    <div class="contact-card__row">
                        <div class="contact_card__content">
                            <div class="contact-card__title">
                                <span>{{ text.email }}</span>
                            </div>
                            <template v-for="(item, index) in email">
                                <div :key="`email-${index}`">
                                    <a class="contact-card__link contact-card__link-mail" :href="`mailto:${item.value}`" size="l">
                                        {{ item.text || item.value }}
                                    </a>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-if="site && site.length">
                        <div class="contact-card__row">
                            <div class="contact_card__content">
                                <div class="contact-card__title">
                                    <span>{{ text.site }}</span>
                                </div>
                                <template v-for="(item, index) in site">
                                    <div :key="`site-${index}`">
                                        <a class="contact-card__link contact-card__link- link--external" target="_blank" :href="`${item.value}`" size="l">
                                            {{ item.text || item.value }}
                                        </a>
                                    </div>
                                </template>
                            </div>
                        </div>
                </template>
            </div>
        </template>
        <template v-else>
            <template v-if="address">
                <template v-for="(item, index) in address">
                    <div class="contact-card__row" :key="`address-${index}`">
                        <div class="contact-card__title">
                            <span v-if="!item.text">{{ text.address }}</span>
                            <div v-else v-html="item.text"/>
                        </div>
                        <div class="contact-card__link">
                            <div v-html="item.value"/>
                        </div>
                    </div>
                </template>
            </template>
            <template v-if="phone">
                <template v-for="(item, index) in phone">
                    <div class="contact-card__row" :key="`phone-${index}`">
                        <div class="contact-card__title">
                            <span v-if="index === 0">{{ text.phone }}</span>
                        </div>
                        <a class="contact-card__link" :href="`tel:${item.value}`" size="l">
                            {{ item.text || item.value }}
                        </a>
                    </div>
                </template>
            </template>
            <template v-if="fax">
                <template v-for="(item, index) in fax">
                    <div class="contact-card__row" :key="`fax-${index}`">
                        <div class="contact-card__title">
                            <span v-if="index === 0">{{ text.fax }}</span>
                        </div>
                        <a class="contact-card__link" :href="`fax:${item.value}`" size="l">
                            {{ item.text || item.value }}
                        </a>
                    </div>
                </template>
            </template>
            <template v-if="email">
                <template v-for="(item, index) in email">
                    <div class="contact-card__row" :key="`email-${index}`">
                        <div class="contact-card__title">
                            <span v-if="index === 0">{{ text.email }}</span>
                        </div>
                        <a class="contact-card__link contact-card__link-mail" :href="`mailto:${item.value}`" size="l">
                            {{ item.text || item.value }}
                        </a>
                    </div>
                </template>
            </template>
            <template v-if="site">
                <template v-for="(item, index) in site">
                    <div class="contact-card__row" :key="`site-${index}`">
                        <div class="contact-card__title">
                            <span v-if="index === 0">{{ text.site }}</span>
                        </div>
                        <a class="contact-card__link contact-card__link-site link--external" target="_blank" :href="`${item.value}`" size="l">
                            {{ item.text || item.value }}
                        </a>
                    </div>
                </template>
            </template>
        </template>
        <slot></slot>
    </div>
</template>

<script>
// import {localize} from '@/utils/i18n'

export default {
    name: 'contact-card',
    props: {
        photo: String,
        phone: {
            type: Array
        },
        fax: {
            type: Array
        },
        email: {
            type: Array
        },
        site: {
            type: Array
        },
        address: {
            type: Array
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            text: {
                phone: localize({
                    ru: 'Телефон:',
                    en: 'Telephone:'
                }),
                fax: localize({
                    ru: 'Факс:',
                    en: 'Fax:'
                }),
                email: localize({
                    ru: 'E-mail:',
                    en: 'E-mail:'
                }),
                site: localize({
                    ru: 'Сайт:',
                    en: 'Website:'
                }),
                address: localize({
                    ru: 'Адрес:',
                    en: 'Address:'
                })
            }
        }
    },
    computed: {
        hasNameSlot () {
            return !!this.$slots['name']
        },
        hasDescriptionSlot () {
            return !!this.$slots['description']
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
