import Vue from 'vue';
import FreightRequestForm from './FreightRequestForm.vue'

const root = document.getElementById('freight-request-form');
const exists = typeof (root) != 'undefined' && root != null;

if (exists) FreightRequestFormApp();

function FreightRequestFormApp() {
    new Vue({
        el: '#freight-request-form',
        components: {
            FreightRequestForm
        }
    });
};