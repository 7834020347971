var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("figure", { staticClass: "media-library-card" }, [
    _c(
      "a",
      {
        staticClass: "tile u-underline--no u-entire-height",
        attrs: { href: _vm.item.link, target: "_blank" }
      },
      [
        _c("div", {
          staticClass: "tile__img",
          style: "background-image: url(" + _vm.item.image + ")"
        }),
        _vm._v(" "),
        _c("figcaption", {
          staticClass: "tile__content u-medium text-size-xl",
          domProps: { innerHTML: _vm._s(_vm.item.name) }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f37939ae", { render: render, staticRenderFns: staticRenderFns })
  }
}