<template>
    <div class="page-changer">
        <a
            :href="data.prev ? data.prev.url : 'javascript:void(0)'"
            :class="[
                'page-changer__link page-changer__link--prev',
                { 'is-disabled': !(data.prev && data.prev.url) }
            ]"
        >
            <span class="page-changer__link-icon">
                <svg width="9" height="65" viewBox="0 0 9 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.87274 0.176433C4.63697 -0.0593331 4.25472 -0.0593331 4.01895 0.176433L0.176906 4.01847C-0.058861 4.25424 -0.058861 4.6365 0.176906 4.87226C0.412673 5.10803 0.794926 5.10803 1.03069 4.87226L4.44584 1.45711L7.86099 4.87226C8.09676 5.10803 8.47901 5.10803 8.71478 4.87226C8.95055 4.6365 8.95055 4.25424 8.71478 4.01847L4.87274 0.176433ZM5.04956 65L5.04956 0.603326H3.84212L3.84212 65H5.04956Z" fill="#0077C8"/>
                </svg>
            </span>

            <span class="page-changer__link-text hide-me--mobile">
                {{ data.prev && data.prev.name || text.prev }}
            </span>
        </a>

        <div class="page-changer__button-wrapper">
            <z-button
                v-if="data['back-link']"
                class="page-changer__button u-bold"
                tag="a"
                kind="secondary"
                size="s"
                :href="data['back-link']"
            >
                <span v-html="text.backLink"/>
            </z-button>
        </div>

        <a
            :href="data.next ? data.next.url : 'javascript:void(0)'"
            :class="
                ['page-changer__link page-changer__link--next',
                { 'is-disabled': !(data.next && data.next.url) }
            ]"
        >
            <span class="page-changer__link-text hide-me--mobile">
                {{ data.next && data.next.name || text.next }}
            </span>

            <span class="page-changer__link-icon">
                <svg width="9" height="65" viewBox="0 0 9 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.87274 0.176433C4.63697 -0.0593331 4.25472 -0.0593331 4.01895 0.176433L0.176906 4.01847C-0.058861 4.25424 -0.058861 4.6365 0.176906 4.87226C0.412673 5.10803 0.794926 5.10803 1.03069 4.87226L4.44584 1.45711L7.86099 4.87226C8.09676 5.10803 8.47901 5.10803 8.71478 4.87226C8.95055 4.6365 8.95055 4.25424 8.71478 4.01847L4.87274 0.176433ZM5.04956 65L5.04956 0.603326H3.84212L3.84212 65H5.04956Z" fill="#0077C8"/>
                </svg>
            </span>
        </a>
    </div>
</template>

<script>
import ZButton from '../ZButton';

export default {
    name: 'page-changer',
    components: {
        ZButton
    },
    props: {
        data: {
            required: true,
            type: Object
        }
    },
    data () {
        return {
            text: {
                prev: localize({
                    ru: 'Предыдущая',
                    en: 'Previous'
                }),
                next: localize({
                    ru: 'Следующая',
                    en: 'Next'
                }),
                backLink: localize({
                    ru: 'Вернуться к&nbsp;списку',
                    en: 'Back to&nbsp;list'
                })
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
