var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.itemData
    ? _c(
        "div",
        { staticClass: "newspaper-page-item" },
        [
          _c("div", { staticClass: "newspaper-page-item__wrapper" }, [
            _vm.itemData.previewPicture.link
              ? _c("div", { staticClass: "newspaper-page-item__image" }, [
                  _c("img", {
                    attrs: { src: _vm.itemData.previewPicture.link }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "newspaper-page-item__info" }, [
              _c("h2", { staticClass: "newspaper-page-item__title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.itemData.name) +
                    " от " +
                    _vm._s(_vm.formatDate(_vm.itemData.activeFrom)) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _vm.itemData.readOnline.value
                ? _c(
                    "a",
                    {
                      staticClass: "link newspaper-page-item__online",
                      attrs: {
                        href: _vm.itemData.readOnline.value,
                        target: "_blank"
                      }
                    },
                    [_vm._v("\n                Читать онлайн\n            ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.itemData.file.name
                ? _c(
                    "a",
                    {
                      staticClass:
                        "link newspaper-page-item__link newspaper-page-item-link",
                      attrs: {
                        href: _vm.itemData.file.link,
                        download: _vm.itemData.file.name,
                        target: "_blank"
                      }
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.itemData.file.name) }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "newspaper-page-item-link__info" },
                        [
                          _c("span", {
                            staticClass: "newspaper-page-item-link__type",
                            domProps: {
                              innerHTML: _vm._s(_vm.itemData.file.type)
                            }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "newspaper-page-item-link__size",
                            domProps: {
                              innerHTML: _vm._s(_vm.itemData.file.size)
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.itemData.detailText && _vm.itemData.detailText.length
            ? [
                _c(
                  "button",
                  {
                    staticClass: "link newspaper-page-item-toggle",
                    class: { "is-opened": _vm.contentVisible },
                    attrs: { type: "button" },
                    on: { click: _vm.toggleBlock }
                  },
                  [
                    _c("span", {
                      staticClass: "newspaper-page-item-toggle__text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.contentVisible
                            ? "Скрыть содержание"
                            : "Смотреть содержание"
                        )
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("transition", { attrs: { name: _vm.transitionName } }, [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.contentVisible,
                        expression: "contentVisible"
                      }
                    ],
                    staticClass: "newspaper-page-item__content",
                    domProps: { innerHTML: _vm._s(_vm.itemData.detailText) }
                  })
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-04fc1918", { render: render, staticRenderFns: staticRenderFns })
  }
}