var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media-items" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.items, function(item) {
        return _c("div", { staticClass: "media-items__item col-default-12" }, [
          _vm.items.length > 1
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-default-12" }, [
                  _c("h2", [_vm._v(_vm._s(item.title))])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(item.items, function(el, index) {
              return _c(
                "div",
                { staticClass: "col-default-4 col-tablet-6 col-mobile-12" },
                [
                  _c(
                    "figure",
                    {
                      staticClass:
                        "media__item figure figure--gradient u-margin--vetical",
                      class: _vm.setItemClass(el.type)
                    },
                    [
                      _c("div", { staticClass: "figure__content" }, [
                        _c("img", {
                          staticClass: "figure__image",
                          attrs: {
                            src: el.image.preview,
                            "data-object-fit": "cover"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "figcaption",
                        { staticClass: "figure__caption media__caption" },
                        [
                          el.name
                            ? _c("span", { staticClass: "figure__text" }, [
                                _vm._v(_vm._s(el.name))
                              ])
                            : _c("span", { staticClass: "figure__text" }, [
                                _vm._v(_vm._s(item.title))
                              ]),
                          _vm._v(" "),
                          el.info
                            ? _c("div", { staticClass: "figure__info" }, [
                                _vm._v(_vm._s(el.info))
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "media__add" }, [
                        _c("div", {
                          staticClass: "media__zoom",
                          attrs: {
                            "data-id": el.id,
                            "data-og-title": el.name,
                            "data-src": el.image.full,
                            "data-thumb": el.image.preview,
                            "data-sub-html": _vm.createItemSubHTML(el),
                            title: _vm.text.show
                          }
                        }),
                        _vm._v(" "),
                        el.type == "photo"
                          ? _c("a", {
                              staticClass: "media__download",
                              attrs: {
                                href: el.image.full,
                                title: _vm.text.download,
                                download: ""
                              }
                            })
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-055e7672", { render: render, staticRenderFns: staticRenderFns })
  }
}