var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.suppliers_data
    ? _c(
        "div",
        { staticClass: "suppliers-services col-default-10 col-tablet-12" },
        [
          _c("div", { staticClass: "suppliers-services__select" }, [
            _c(
              "select",
              { staticClass: "js-select", on: { change: _vm.filterHandler } },
              [
                _c("option", { attrs: { value: "" } }, [_vm._v("Все года")]),
                _vm._v(" "),
                _vm._l(_vm.filter.year, function(ref) {
                  var id = ref.id
                  var xmlId = ref.xmlId
                  var text = ref.text
                  return _c("option", {
                    key: id,
                    domProps: { value: xmlId, innerHTML: _vm._s(text) }
                  })
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "suppliers-services__list" },
            _vm._l(_vm.suppliers_data, function(item, idx) {
              return _c("tender-card", {
                key: idx,
                staticClass: "suppliers-services__item",
                attrs: { item: item }
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.isLoading ? _c("z-preloader") : _vm._e(),
          _vm._v(" "),
          _vm.paginationData
            ? _c("z-pagination", {
                staticClass: "u-top-margin--xl-important",
                attrs: {
                  "show-next": "",
                  "show-prev": "",
                  data: _vm.paginationData
                },
                on: { "change-page": _vm.paginationHandler }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-476c8764", { render: render, staticRenderFns: staticRenderFns })
  }
}