<template>
    <div class="media-items">
        <div class="row">
            <div v-for="item in items" class="media-items__item col-default-12">
                <div v-if="items.length > 1" class="row">
                    <div class="col-default-12">
                        <h2>{{ item.title }}</h2>
                    </div>
                </div>
                <div class="row">
                    <div
                        v-for="(el, index) in item.items"
                        class="col-default-4 col-tablet-6 col-mobile-12"
                    >
                        <figure
                            :class="setItemClass(el.type)"
                            class="media__item figure figure--gradient u-margin--vetical"
                        >
                            <div class="figure__content">
                                <img :src="el.image.preview" class="figure__image" data-object-fit="cover">
                            </div>
                            <figcaption class="figure__caption media__caption">
                                <span v-if="el.name" class="figure__text">{{ el.name }}</span>
                                <span v-else class="figure__text">{{ item.title }}</span>
                                <!-- <time class="figure__date"><i>{{ formatItemDate(el.date) }}</i></time> -->
                                <div v-if="el.info" class="figure__info">{{ el.info }}</div>
                            </figcaption>
                            <div class="media__add">
                                <div
                                    :data-id="el.id"
                                    :data-og-title="el.name"
                                    :data-src="el.image.full"
                                    :data-thumb="el.image.preview"
                                    :data-sub-html="createItemSubHTML(el)"
                                    :title="text.show"
                                    class="media__zoom"
                                ></div>
                                <a
                                    v-if="el.type == 'photo'"
                                    :href="el.image.full"
                                    :title="text.download"
                                    class="media__download"
                                    download
                                ></a>
                            </div>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

export default {
    name: 'media-items',
    props: {
        items: Array
    },
    data() {
        return {
            gallery: Object,
            currentSlide: {
                id: String,
                title: String
            },
            shareHandler: '/ajax/social-share.php',
            text: {
                show: this.$parent.lang == 'ru' ? 'Просмотреть' : 'Show',
                download: this.$parent.lang == 'ru' ? 'Скачать' : 'Download'
            }
        }
    },
    mounted() {
        this.offsetTop();
        this.$nextTick(() => this.initGallery());
    },
    updated() {
        this.offsetTop();
        this.$nextTick(() => this.initGallery());
    },
    computed: {
        debouncedShare() {
            return debounce(500, () => this.setItemShare());
        }
    },
    methods: {
        initGallery() {
            this.gallery = $(this.$el);

            this.gallery.lightGallery({
                selector: '.media__zoom',
                slideEndAnimatoin: false,
                hideControlOnEnd: true,
                loop: false,
                counter: false,
                hash: false,
                videojs: true,
                galleryId: 1,
                thumbnail: true,
                fullScreen: false,
                thumbContHeight: 120,
                thumbMargin: 20,
                exThumbImage: 'data-thumb',
                animateThumb: true,
                toogleThumb: false,
                currentPagerPosition: 'middle',
                share: true,
                facebook: false,
                twitter: false,
                googlePlus: false,
                pinterest: false
            });

            this.gallery.on('onAfterAppendSubHtml.lg', (event, index) => {
                let slide = this.$el.querySelectorAll('.media__zoom')[index],
                    slideID = slide.getAttribute('data-id'),
                    slideTitle = slide.getAttribute('data-og-title');

                this.currentSlide.id = slideID;
                this.currentSlide.title = slideTitle;
                this.$parent.$emit('changeID', slideID);

                this.debouncedShare();
            });

            this.gallery.on('onCloseAfter.lg', () => this.$parent.$emit('changeID', ''));

            this.triggerOpenModal();
        },
        formatItemDate(date) {
            moment.lang(this.$parent.lang);
            return moment.unix(date).format('D MMMM YYYY');
        },
        createItemSubHTML(item) {
            let html = `
                <div class="media__header">
                    <time><i>${this.formatItemDate(item.date)}</i></time>
                    <span class="media__title serif-bottom-blue">${item.name}</span>
                </div>
            `;

            if (item.detail) html += `<div class="media__body"><p>${item.detail}</p></div>`;

            return html;
        },
        setItemClass(type) {
            if (type == 'video') {
                return 'media__item--' + type;
            } else {
                return 'media__item--download media__item--' + type;
            };
        },
        setItemShare() {
            let btn = document.querySelector('#lg-share'),
                list = btn.querySelector('ul'),
                url = `${this.shareHandler}?ogTitle=${this.currentSlide.title}&ogURL=${this.$parent.setLocationParams()}`;
            
                if (!list) {
                    btn.style.display = 'none'
                }

            list.innerHTML = `<li><img src="/images/svg/loader.svg"></li>`;

            fetch(url)
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        let error = new Error(response.statusText);
                        error.response = response;
                        throw error;
                    };
                }).then(response => {
                    return response.clone().text();
                })
                .then(html => {
                    btn.innerHTML = html;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        triggerOpenModal() {
            if (this.$parent.params.id) {
                this.currentSlide.id = this.$parent.params.id;
                this.$el.querySelector(`.media__zoom[data-id="${this.currentSlide.id}"]`).click();
            };
        },
        offsetTop() {
            this.$parent.offsetTop = (this.items.length > 1) ? true : false;
        }
    }
};
</script>
