var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "projects-page" },
    [
      _c("p", [
        _vm._v(
          "\n        Почти 90 лет Енисейское речное пароходство возит грузы по Енисею и его притокам. \n        Пароходство твердо стоит на ногах, работая в ритме краевой экономики. \n        Енисейским пароходством сегодня активно изыскиваются и осваиваются новые грузопотоки. \n        Реализация любого, значимого для экономики Таймыра, Эвенкии, Красноярского края, проекта не обходится без участия речников.\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n        ЕРП является основным транспортным партнером в крупнейших инвестиционных проектах Сибири: \n        программе освоения Нижнего Приангарья, развития Ванкорского нефтегазового месторождения, \n        освоения Юрубчено-Тахомского месторождения, строительство Богучанской ГЭС, \n        развития Норильского промышленного района, строительство нефтепровода Куюмба-Тайшет и др.\n    "
        )
      ]),
      _vm._v(" "),
      _vm.items && _vm.items.length
        ? _c(
            "div",
            { ref: "container", staticClass: "projects-page__cards-list" },
            [_c("results", { attrs: { data: _vm.items } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading ? _c("z-preloader") : _vm._e(),
      _vm._v(" "),
      _vm.paginationData
        ? _c("z-pagination", {
            staticClass: "u-top-margin--3xl-important",
            attrs: {
              "show-next": "",
              "show-prev": "",
              data: _vm.paginationData
            },
            on: { "change-page": _vm.paginationHandler }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-494e13a0", { render: render, staticRenderFns: staticRenderFns })
  }
}