<template>
    <form @submit.prevent="sendFormData" class="freight-request-form">
        <h5 class="freight-request-form__title">Уважаемые Клиенты!</h5>
        <p class="freight-request-form__text">
            Енисейское пароходство предлагает Вам сервис для удобного оформления запросов на перевозку грузов нашей компанией. 
            Заполните предлагаемый электронный бланк и нажмите кнопку «Отправить». 
            После обработки Вашей заявки менеджеры компании свяжутся с Вами.
        </p>

        <z-input
            v-model="formData['point-of-departure']"
            type="text"
            name="point-of-departure"
            ref="point-of-departure"
            :title="text.pointOfDeparture.title"
            :placeholder="text.pointOfDeparture.placeholder"
            required
            class="freight-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['destination']"
            type="text"
            name="destination"
            ref="destination"
            :title="text.destination.title"
            :placeholder="text.destination.placeholder"
            required
            class="freight-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['departure-period']"
            type="text"
            name="departure-period"
            ref="departure-period"
            :title="text.departurePeriod.title"
            :placeholder="text.departurePeriod.placeholder"
            required
            class="freight-request-form__input"
        ></z-input>

        <h5 class="freight-request-form__table-title">Характеристики груза</h5>

        <div class="freight-request-form__table">
            <div class="freight-request-form__table-wrapper">
                <div class="freight-request-form__table-item">
                    <div class="freight-request-form__table-item--title">
                        <span>{{ text.shippingName.title }}</span>
                    </div>

                    <div class="freight-request-form__table-item--body">
                        <z-input
                            v-model="formData['shipping-name']"
                            type="text"
                            name="shipping-name"
                            ref="shipping-name"
                            :clearable="false"
                            required
                            class="freight-request-form__input"
                        ></z-input>
                    </div>
                </div>

                <div class="freight-request-form__table-item">
                    <div class="freight-request-form__table-item--title">
                        <span>{{ text.receiptOfCargo.title }}</span>
                    </div>

                    <div class="freight-request-form__table-item--body">
                        <z-input
                            v-model="formData['receipt-of-cargo']"
                            type="text"
                            name="receipt-of-cargo"
                            ref="receipt-of-cargo"
                            :clearable="false"
                            required
                            class="freight-request-form__input"
                        ></z-input>
                    </div>
                </div>

                <div class="freight-request-form__table-item">
                    <div class="freight-request-form__table-item--title">
                        <span>{{ text.specifications.title }}</span>
                    </div>

                    <div class="freight-request-form__table-item--body">
                        <z-input
                            v-model="formData['specifications']"
                            type="text"
                            name="specifications"
                            ref="specifications"
                            :clearable="false"
                            required
                            class="freight-request-form__input"
                        ></z-input>
                    </div>
                </div>

                <div class="freight-request-form__table-item">
                    <div class="freight-request-form__table-item--title">
                        <span>{{ text.typeOfPackaging.title }}</span>
                    </div>

                    <div class="freight-request-form__table-item--body">
                        <z-input
                            v-model="formData['type-of-packaging']"
                            type="text"
                            name="type-of-packaging"
                            ref="type-of-packaging"
                            :clearable="false"
                            required
                            class="freight-request-form__input"
                        ></z-input>
                    </div>
                </div>
            </div>
            
            <div class="freight-request-form__table-wrapper">
                <div class="freight-request-form__table-item">
                    <div class="freight-request-form__table-item--title">
                        <span>{{ text.numberOfCargo.title }}</span>
                    </div>

                    <div class="freight-request-form__table-item--body">
                        <z-input
                            v-model="formData['number-of-cargo']"
                            type="number"
                            name="number-of-cargo"
                            ref="number-of-cargo"
                            :clearable="false"
                            required
                            class="freight-request-form__input"
                        ></z-input>
                    </div>
                </div>

                <div class="freight-request-form__table-item">
                    <div class="freight-request-form__table-item--title">
                        <span>{{ text.weightItem.title }}</span>
                    </div>

                    <div class="freight-request-form__table-item--body">
                        <z-input
                            v-model="formData['weight-item']"
                            type="number"
                            name="weight-item"
                            ref="weight-item"
                            :clearable="false"
                            required
                            class="freight-request-form__input"
                        ></z-input> 
                    </div>
                </div>

                <div class="freight-request-form__table-item freight-request-form__table-item--nesting">
                    <div class="freight-request-form__table-item--title">
                        <span>{{ text.dimensions.title }}</span>
                    </div>

                    <div class="freight-request-form__table-body--nesting">
                        <div class="freight-request-form__table-item">
                            <div class="freight-request-form__table-item--title">
                                <span>{{ text.dimensions.fields['length'] }}</span>
                            </div>

                            <div class="freight-request-form__table-item--body">
                                <z-input
                                    v-model="formData['dimensions-length']"
                                    type="number"
                                    name="dimensions-length"
                                    ref="dimensions-length"
                                    :clearable="false"
                                    required
                                    class="freight-request-form__input"
                                ></z-input>
                            </div>
                        </div>

                        <div class="freight-request-form__table-item">
                            <div class="freight-request-form__table-item--title">
                                <span>{{ text.dimensions.fields.width }}</span>
                            </div>

                            <div class="freight-request-form__table-item--body">
                                <z-input
                                    v-model="formData['dimensions-width']"
                                    type="number"
                                    name="dimensions-width"
                                    ref="dimensions-width"
                                    :clearable="false"
                                    required
                                    class="freight-request-form__input"
                                ></z-input>
                            </div>
                        </div>

                        <div class="freight-request-form__table-item">
                            <div class="freight-request-form__table-item--title">
                                <span>{{ text.dimensions.fields.height }}</span>
                            </div>

                            <div class="freight-request-form__table-item--body">
                                <z-input
                                    v-model="formData['dimensions-height']"
                                    type="number"
                                    name="dimensions-height"
                                    ref="dimensions-height"
                                    :clearable="false"
                                    required
                                    class="freight-request-form__input"
                                ></z-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="freight-request-form__input--file">
            <z-input-file
                name="file"
                ref="fileInput"
                :placeholder="text.file.placeholder"
                @change="onChageFile"
                accept=".xlsx, .docx, image/png, image/jpeg"
            />
        </div>

        <z-input
            v-model="formData['organization-name']"
            type="text"
            name="organization-name"
            ref="organization-name"
            :title="text.organizationName.title"
            :placeholder="text.organizationName.placeholder"
            required
            class="freight-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['position-supervisor']"
            type="text"
            name="position-supervisor"
            ref="position-supervisor"
            :title="text.positionSupervisor.title"
            required
            class="freight-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['fio-supervisor']"
            type="text"
            name="fio-supervisor"
            ref="fio-supervisor"
            :title="text.fioSupervisor.title"
            required
            class="freight-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['fio-contact']"
            type="text"
            name="fio-contact"
            ref="fio-contact"
            :title="text.fioContact.title"
            :placeholder="text.fioContact.placeholder"
            required
            class="freight-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['phone-contact']"
            type="tel"
            mask="+7(###)###-##-##"
            name="phone-contact"
            ref="phone-contact"
            :title="text.phoneContact.title"
            required
            class="freight-request-form__input"
        ></z-input>

        <z-input
            v-model="formData['email-contact']"
            type="email"
            name="email-contact"
            ref="email-contact"
            :title="text.emailContact.title"
            required
            class="freight-request-form__input"
        ></z-input>

        <z-textarea
            v-model="formData['additional-info']"
            name="additional-info"
            ref="additional-info"
            :title="text.additionalInfo.title"
            :placeholder="formData['additional-info'] ? '' : text.additionalInfo.placeholder"
            class="freight-request-form__input freight-request-form__input--textarea"
        ></z-textarea>

        <p class="freight-request-form__captcha-title">{{ text.captcha }}</p>
        <div class="freight-request-form__captcha-wrapper">
            <div class="freight-request-form__captcha-container">
                <img
                    v-if="isUpdatedCapcha"
                    :src="'/api/captcha/?' + capchaSalt"
                    width="100"
                    height="50"
                    alt=""
                    class="freight-request-form__captcha-img"
                />
                <div v-else class="freight-request-form__captcha-img"></div>
            </div>
        
            <z-button
                type="button"
                kind="secondary"
                rounded
                @click="updateCapcha"
                class="freight-request-form__captcha-button"
            >
                <svg version="1.1" viewBox="0 0 24 24" class="z-icon z-fill" style="width: 24px; height: 24px;">
                    <path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.285 4.184l-3.477 3.477-.707-.707 2.552-2.552A7.613 7.613 0 005.02 15.75a.5.5 0 01-.866.5 8.613 8.613 0 018.653-12.836L10.101.707 10.808 0l3.477 3.477.353.354-.353.353zm3.921 3.953a.5.5 0 11.866-.5 8.612 8.612 0 01-8.536 12.852.498.498 0 01-.14-.039l2.73 2.73-.707.708-3.477-3.478-.354-.353.354-.354 3.477-3.477.707.707-2.561 2.561a.506.506 0 01.096.003 7.613 7.613 0 007.545-11.36zM10.1 19.96l-.098.098.14.14a.497.497 0 01-.042-.238z" fill="#0077C8"></path>
                </svg>
            </z-button>

            <z-input
                v-model="captcha"
                type="text"
                name="captcha"
                ref="captcha"
                required
                class="freight-request-form__captcha-input"
            ></z-input>
        </div>

        <z-checkbox
            v-model="isChecked"
            name="policy"
            ref="policy"
            :data="[{ value: '1' }]"
            required
            class="freight-request-form__checkbox"
        >
            <template #text>
                <p>
                    Я ознакомился с <a href="/terms/" target="_blank" class="link">Политикой о защите персональных данных</a> 
                    и даю свое согласие на обработку моих персональных данных.
                </p>
            </template>
        </z-checkbox>

        <z-button class="illiquids-request-form__button">{{ text.button }}</z-button>

        <h5 class="illiquids-request-form__note-title">Примечание:</h5>
        <p class="illiquids-request-form__note-text">
            Заявки на перевозку грузов принимаются также в письменном виде на фирменных бланках заявителя на имя Генерального директора. 
            Бланк письменной заявки можно скачать 
            <a 
                href="/upload/forma_zayavki_na_perevozku_202303.docx" 
                title="Заявка на перевозку грузов - файл MS-Word (43 КБ)" 
                class="link"
            >
            здесь
            </a>
            </p>
            <p class="illiquids-request-form__note-text">
                Запросы в печатном виде принимаются по факсу +7(391)266-03-66 или почтой по адресу: 660049, г. Красноярск, ул. Бограда, 15. Канцелярия АО «ЕРП».
            </p>
    </form>
</template>

<script>
import ZButton from "../app/ZButton"
import ZCheckbox from "../app/ZCheckbox"
import ZInput from "../app/ZInput"
import ZTextarea from "../app/ZTextarea"
import ZInputFile from "../app/ZInputFile"
import { text } from "./constans.js"
import { sendFreightForm } from '../../api/freight-request-form.js'
import { sendCaptcha } from '../../api/send-captcha.js'

export default {
    name: 'freight-request-form',
    components: {
        ZButton,
        ZCheckbox,
        ZInput,
        ZTextarea,
        ZInputFile
    },
    data() {
        return {
            isChecked: [],
            isLoading: false,
            formData: {
                'point-of-departure': '',
                'destination': '',
                'departure-period': '',
                'shipping-name': '',
                'receipt-of-cargo': '',
                'specifications': '',
                'type-of-packaging': '',
                'number-of-cargo': '',
                'weight-item': '',
                'dimensions-length': '',
                'dimensions-width': '',
                'dimensions-height': '',
                'file': null,
                'organization-name': '',
                'position-supervisor': '',
                'fio-supervisor': '',
                'fio-contact': '',
                'phone-contact': '',
                'email-contact': '',
                'additional-info': '',
            },
            text,
            captcha: '',
            capchaSalt: Date.now(),
            isUpdatedCapcha: true,
            deleteSelected: false,
        };
    },
    methods: {
        validateInputFormat() {
            if (this.formData['phone-contact'] || this.formData['email-contact']) {
                const requiredFields = ['phone-contact', 'email-contact'];
                let isValid = true;

                requiredFields.forEach((item) => {
                    const field = this.$refs[item];
                    if (field) {
                        field.validate();
                        if (field.error) isValid = false;
                    }
                });
                return isValid;
            }

            return true;
        },

        validateRequiredInputs() {
            const requiredFields = [
                'point-of-departure', 
                'destination', 
                'departure-period', 
                'shipping-name', 
                'receipt-of-cargo',
                'specifications',
                'type-of-packaging',
                'number-of-cargo',
                'weight-item',
                'dimensions-length',
                'dimensions-width',
                'dimensions-height',
                'organization-name',
                'position-supervisor',
                'fio-supervisor',
                'fio-contact',
                'phone-contact',
                'email-contact',
                'captcha', 
                'policy'
            ]
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },

        onChageFile(data) {
            this.formData.file = data
        },

        updateCapcha() {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },

        async sendFormData() {
            if (this.isLoading) return

            const isValidRequiredInputs = this.validateRequiredInputs()
        
            if (isValidRequiredInputs) {
                const isValidInputFormat = this.validateInputFormat()

                if (!isValidInputFormat) {
                    showNotyfications(this.text.notificationValidateError, { type: 'error' })
                    return
                }

                try {
                    this.isLoading = true
                    
                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        let item = this.formData[key]

                        if(key === 'file' && Boolean(this.formData[key])) {
                            item = this.formData[key][0]
                        } 

                        data.append(key, item)
                    }

                    const captchaResponse = await sendCaptcha(this.captcha)

                    if (captchaResponse.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await sendFreightForm(data)

                        if (formResponse.status === 'success') {
                            showNotyfications(formResponse.message, { type: 'success' })

                            this.formData['point-of-departure'] = ''
                            this.formData['destination'] = ''
                            this.formData['departure-period'] = ''
                            this.formData['shipping-name'] = ''
                            this.formData['receipt-of-cargo'] = ''
                            this.formData['specifications'] = ''
                            this.formData['type-of-packaging'] = ''
                            this.formData['number-of-cargo'] = ''
                            this.formData['weight-item'] = ''
                            this.formData['dimensions-length'] = ''
                            this.formData['dimensions-width'] = ''
                            this.formData['dimensions-height'] = ''
                            this.formData['file'] = null
                            this.formData['organization-name'] = ''
                            this.formData['position-supervisor'] = ''
                            this.formData['fio-supervisor'] = ''
                            this.formData['fio-contact'] = ''
                            this.formData['phone-contact'] = ''
                            this.formData['email-contact'] = ''
                            this.formData['additional-info'] = ''
                            this.deleteSelected = true
                            this.isChecked = []

                            this.$refs.fileInput.clear()
                        } else {
                            showNotyfications(formResponse.message, { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        },
    }
};
</script>

<style lang="scss" src="./freight-request-form.scss"></style>