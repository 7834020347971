var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tender-card" }, [
    _vm.item.dateCreate.timestamp
      ? _c("div", { staticClass: "tender-card__header" }, [
          _c("span", { staticClass: "tender-card__text-label" }, [
            _vm._v("Дата публикации:")
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "tender-card__text",
            domProps: {
              innerHTML: _vm._s(_vm.formatDate(_vm.item.dateCreate.timestamp))
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tender-card__body" },
      [
        _c("h1", {
          staticClass: "tender-card__body-title",
          domProps: { innerHTML: _vm._s(_vm.item.description.value) }
        }),
        _vm._v(" "),
        _vm._l(_vm.item.file, function(file, idx) {
          return _vm.item.file && _vm.item.file.length
            ? _c("div", { key: idx, staticClass: "tender-card__body-file" }, [
                _c(
                  "a",
                  {
                    class: _vm.getFileLinkClass(file.type),
                    attrs: { href: file.link, target: "_blank" }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                    Скачать документацию\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "file-list__size" }, [
                      _vm._v(_vm._s(file.type) + " • " + _vm._s(file.size))
                    ])
                  ]
                )
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _c("div", { staticClass: "tender-card__body-content" }, [
          _vm.item.number.value
            ? _c("div", { staticClass: "tender-card__body-item" }, [
                _c("span", { staticClass: "tender-card__text-label" }, [
                  _vm._v("Номер лота:")
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "tender-card__text",
                  domProps: { innerHTML: _vm._s(_vm.item.number.value) }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.endDate.timestamp
            ? _c("div", { staticClass: "tender-card__body-item" }, [
                _c("span", { staticClass: "tender-card__text-label" }, [
                  _vm._v("Приём заявок до:")
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "tender-card__text",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.formatDate(_vm.item.endDate.timestamp)
                    )
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.contact.value
            ? _c("div", { staticClass: "tender-card__body-item" }, [
                _c("span", { staticClass: "tender-card__text-label" }, [
                  _vm._v("Контактное лицо:")
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "tender-card__text",
                  domProps: { innerHTML: _vm._s(_vm.item.contact.value) }
                })
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.item.text.value
          ? _c("div", { staticClass: "tender-card__body-extra" }, [
              _c("span", { staticClass: "tender-card__text-label" }, [
                _vm._v("О подаче заявки:")
              ]),
              _vm._v(" "),
              _c("span", {
                staticClass: "tender-card__text",
                domProps: { innerHTML: _vm._s(_vm.item.text.value) }
              })
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-61838a18", { render: render, staticRenderFns: staticRenderFns })
  }
}