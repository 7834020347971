var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("figure", { staticClass: "project-card" }, [
    _c(
      "a",
      {
        staticClass: "project-card__wrapper",
        attrs: { href: _vm.data.detailPageUrl }
      },
      [
        _c("div", {
          staticClass: "project-card__image",
          style: "background-image: url(" + _vm.data.image.link + ")"
        }),
        _vm._v(" "),
        _c("figcaption", { staticClass: "project-card__caption" }, [
          _c("span", {
            staticClass: "project-card__title",
            domProps: { innerHTML: _vm._s(_vm.data.name) }
          }),
          _vm._v(" "),
          _vm.data.previewText
            ? _c("p", {
                staticClass: "project-card__preview",
                domProps: { innerHTML: _vm._s(_vm.data.previewText) }
              })
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fcada972", { render: render, staticRenderFns: staticRenderFns })
  }
}