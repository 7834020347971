<template>
    <div class="newspaper-page" v-if="list && list.length">
        <div class="newspaper-page__filter">
            <select class="js-select" @change="filterHandler">
                <option></option>
                <option
                    v-for="(item, index) in filters.year"
                    :key="index"
                    :value="item.id"
                    v-html="item.text"
                />
            </select>
        </div>
        <div class="newspaper-page__list">
            <newspaper-page-item
                v-for="item in list"
                :key="item.name"
                :item-data="item"
                class="newspaper-page__item"
            />
        </div>
        <z-preloader v-if="isLoading" />
        <z-pagination
            v-if="paginationData"
            class="u-top-margin--xl-important"
            show-next
            show-prev
            :data="paginationData"
            @change-page="paginationHandler"
        ></z-pagination>
    </div>
</template>

<script>
import NewspaperPageItem from "./components/NewspaperPageItem.vue";
import { throttle } from 'throttle-debounce'
const qs = require('query-string')
import ZPagination from '../app/ZPagination/ZPagination.vue'
import ZPreloader from '../app/ZPreloader/ZPreloader.vue'

export default {
    name: 'newspaper-page',
    components: {
        ZPreloader,
        ZPagination,
        NewspaperPageItem
    },
    data () {
        return {
            isLoading: false,
            params: {
                year: null,
                page: 1
            },
            filters: App.components['yenisei-newspaper']?.filters || [],
            list: App.components['yenisei-newspaper']?.items || null,
            nav: App.components['yenisei-newspaper']?.nav || null
        }
    },
    mounted () {
        $('.js-select')
            .select2({
                placeholder: "Выберите год",
                theme: 'filled',
                minimumResultsForSearch: Infinity
            })
            .on('select2:select', this.filterHandler);
    },
    methods: {
        filterHandler (e) {
            let data = e.params.data;
            this.params.year = data.id
            this.params.page = 1
            this.send()
        },
        paginationHandler(data) {
            this.params.page = data.page
            this.send()
        },
        send: throttle(500, function () {
            this.isLoading = true

            let queryParams = qs.stringify(this.params)

            fetch(`/api/yenisei-newspaper/?${queryParams}`)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        let error = new Error(response.statusText);
                        error.response = response;
                        throw error;
                    };
                })
                .then(response => {
                    this.list = response.items
                    this.nav = response.nav
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false
                })
        })
    },
    computed: {
        paginationData() {
            if (this.nav) {
                return {
                    currentPage: this.nav?.current,
                    totalItems: this.nav?.count,
                    totalPages: this.nav?.total,
                    pageRange: 1
                }
            }

            return null
        }
    },
}
</script>