<template>
    <div class="suppliers-services col-default-10 col-tablet-12" v-if="suppliers_data">
        <div class="suppliers-services__select">
            <select class="js-select" @change="filterHandler">
                <option value="">Все года</option>
                <option
                    v-for="{ id, xmlId, text } in filter.year"
                    :key="id"
                    :value="xmlId"
                    v-html="text"
                />
            </select>
        </div>
        <div class="suppliers-services__list">
            <tender-card
                v-for="(item, idx) in suppliers_data"
                :key="idx"
                :item="item"
                class="suppliers-services__item"
            />
        </div>
        <z-preloader v-if="isLoading" />
        <z-pagination
            v-if="paginationData"
            class="u-top-margin--xl-important"
            show-next
            show-prev
            :data="paginationData"
            @change-page="paginationHandler"
        ></z-pagination>
    </div>
</template>

<script>
import TenderCard from '../tender-card/TenderCard.vue'
import ZPagination from '../app/ZPagination/ZPagination.vue'
import ZPreloader from '../app/ZPreloader/ZPreloader.vue'
import { throttle } from 'throttle-debounce'
const qs = require('query-string')

export default {
    name: 'suppliers-services',
    components: {
        TenderCard,
        ZPagination,
        ZPreloader
    },
    data() {
        return {
            isLoading: false,
            nav: this.$root.app.components?.['suppliers-services']?.nav || null,
            suppliers_data: this.$root.app.components?.['suppliers-services']?.items || null,
            filter: this.$root.app.components?.['suppliers-services']?.filter || {},
            params: {
                year: '',
                page: 1
            }
        }
    },
    computed: {
        paginationData() {
            if (this.nav) {
                return {
                    currentPage: this.nav?.current,
                    totalItems: this.nav?.count,
                    totalPages: this.nav?.total,
                    pageRange: 1
                }
            }

            return null
        }
    },
    mounted() {
        $('.js-select')
            .select2({
                theme: 'filled',
                minimumResultsForSearch: Infinity
            })
            .on('select2:select', this.filterHandler)
    },
    methods: {
        filterHandler(e) {
            let data = e.params.data
            this.params.year = data.id
            this.params.page = 1
            this.fetchFilteredData()
        },
        paginationHandler(data) {
            this.params.page = data.page
            this.fetchFilteredData()
        },
        fetchFilteredData: throttle(500, function() {
            this.isLoading = true

            let queryParams = qs.stringify(this.params)
            fetch(`/api/suppliers-services/?${queryParams}`)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json()
                    } else {
                        let error = new Error(response.statusText)
                        error.response = response
                        throw error
                    }
                })
                .then((response) => {
                    this.suppliers_data = response.items
                    this.nav = response.nav
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        })
    }
}
</script>

<style scoped lang="scss">
@import './suppliers-services.scss';
</style>
