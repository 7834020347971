import Vue from 'vue'
import MediaLibrary from './MediaLibrary.vue'

const container = document.getElementById('media-library')
const exists = typeof (container) != 'undefined' && container != null


if (exists) {
    new Vue({
        el: '#media-library',
        components: {
            MediaLibrary,
        },
        data: {
            lang: getLang(),
            app: window.App
        },
    })
}