var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "illiquids-request-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.sendFormData.apply(null, arguments)
        }
      }
    },
    [
      _c("h5", { staticClass: "illiquids-request-form__title" }, [
        _vm._v("Уважаемые Клиенты!")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "illiquids-request-form__text" }, [
        _vm._v(
          "\n        Енисейское пароходство предлагает Вам сервис для удобного оформления заявки на приобретение неликвидных материалов (МПЗ). \n        Заполните предлагаемый электронный бланк и нажмите кнопку «Отправить». Срок рассмотрения заявки до 3-х рабочих дней. \n        После обработки Вашей заявки менеджеры компании свяжутся с Вами.\n    "
        )
      ]),
      _vm._v(" "),
      _c("z-input", {
        ref: "material-name",
        staticClass: "illiquids-request-form__input",
        attrs: {
          type: "text",
          name: "material-name",
          title: _vm.text.materialName.title,
          placeholder: _vm.text.materialName.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["material-name"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "material-name", $$v)
          },
          expression: "formData['material-name']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "brand-engine",
        staticClass: "illiquids-request-form__input",
        attrs: {
          type: "text",
          name: "brand-engine",
          title: _vm.text.brandEngine.title,
          placeholder: _vm.text.brandEngine.placeholder
        },
        model: {
          value: _vm.formData["brand-engine"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "brand-engine", $$v)
          },
          expression: "formData['brand-engine']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "number",
        staticClass: "illiquids-request-form__input",
        attrs: {
          type: "number",
          name: "number",
          validateOnBlur: "",
          title: _vm.text.number.title,
          placeholder: _vm.text.number.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["number"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "number", $$v)
          },
          expression: "formData['number']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "amount",
        staticClass: "illiquids-request-form__input",
        attrs: {
          type: "text",
          name: "amount",
          title: _vm.text.amount.title,
          placeholder: _vm.text.amount.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["amount"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "amount", $$v)
          },
          expression: "formData['amount']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "price",
        staticClass: "illiquids-request-form__input",
        attrs: {
          type: "text",
          name: "price",
          title: _vm.text.price.title,
          placeholder: _vm.text.price.placeholder
        },
        model: {
          value: _vm.formData["price"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "price", $$v)
          },
          expression: "formData['price']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "organization-name",
        staticClass: "illiquids-request-form__input",
        attrs: {
          type: "text",
          name: "organization-name",
          title: _vm.text.organizationName.title,
          placeholder: _vm.text.organizationName.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["organization-name"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "organization-name", $$v)
          },
          expression: "formData['organization-name']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "fio",
        staticClass: "illiquids-request-form__input",
        attrs: {
          type: "text",
          name: "fio",
          title: _vm.text.fio.title,
          placeholder: _vm.text.fio.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["fio"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "fio", $$v)
          },
          expression: "formData['fio']"
        }
      }),
      _vm._v(" "),
      _c("z-input", {
        ref: "contact-info",
        staticClass: "illiquids-request-form__input",
        attrs: {
          type: "text",
          name: "contact-info",
          title: _vm.text.contactInfo.title,
          placeholder: _vm.text.contactInfo.placeholder,
          required: ""
        },
        model: {
          value: _vm.formData["contact-info"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "contact-info", $$v)
          },
          expression: "formData['contact-info']"
        }
      }),
      _vm._v(" "),
      _c("z-textarea", {
        ref: "additional-info",
        staticClass:
          "illiquids-request-form__input illiquids-request-form__input--textarea",
        attrs: {
          name: "additional-info",
          title: _vm.text.additionalInfo.title,
          placeholder: _vm.formData["additional-info"]
            ? ""
            : _vm.text.additionalInfo.placeholder
        },
        model: {
          value: _vm.formData["additional-info"],
          callback: function($$v) {
            _vm.$set(_vm.formData, "additional-info", $$v)
          },
          expression: "formData['additional-info']"
        }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "illiquids-request-form__captcha-title" }, [
        _vm._v(_vm._s(_vm.text.captcha))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "illiquids-request-form__captcha-wrapper" },
        [
          _c(
            "div",
            { staticClass: "illiquids-request-form__captcha-container" },
            [
              _vm.isUpdatedCapcha
                ? _c("img", {
                    staticClass: "illiquids-request-form__captcha-img",
                    attrs: {
                      src: "/api/captcha/?" + _vm.capchaSalt,
                      width: "100",
                      height: "50",
                      alt: ""
                    }
                  })
                : _c("div", {
                    staticClass: "illiquids-request-form__captcha-img"
                  })
            ]
          ),
          _vm._v(" "),
          _c(
            "z-button",
            {
              staticClass: "illiquids-request-form__captcha-button",
              attrs: { type: "button", kind: "secondary", rounded: "" },
              on: { click: _vm.updateCapcha }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "z-icon z-fill",
                  staticStyle: { width: "24px", height: "24px" },
                  attrs: { version: "1.1", viewBox: "0 0 24 24" }
                },
                [
                  _c("path", {
                    attrs: {
                      pid: "0",
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M14.285 4.184l-3.477 3.477-.707-.707 2.552-2.552A7.613 7.613 0 005.02 15.75a.5.5 0 01-.866.5 8.613 8.613 0 018.653-12.836L10.101.707 10.808 0l3.477 3.477.353.354-.353.353zm3.921 3.953a.5.5 0 11.866-.5 8.612 8.612 0 01-8.536 12.852.498.498 0 01-.14-.039l2.73 2.73-.707.708-3.477-3.478-.354-.353.354-.354 3.477-3.477.707.707-2.561 2.561a.506.506 0 01.096.003 7.613 7.613 0 007.545-11.36zM10.1 19.96l-.098.098.14.14a.497.497 0 01-.042-.238z",
                      fill: "#0077C8"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("z-input", {
            ref: "captcha",
            staticClass: "illiquids-request-form__captcha-input",
            attrs: { type: "text", name: "captcha", required: "" },
            model: {
              value: _vm.captcha,
              callback: function($$v) {
                _vm.captcha = $$v
              },
              expression: "captcha"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("z-checkbox", {
        ref: "policy",
        staticClass: "illiquids-request-form__checkbox",
        attrs: { name: "policy", data: [{ value: "1" }], required: "" },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c("p", [
                  _vm._v("\n                Я ознакомился с "),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      attrs: { href: "/terms/", target: "_blank" }
                    },
                    [_vm._v("Политикой о защите персональных данных")]
                  ),
                  _vm._v(
                    " \n                и даю свое согласие на обработку моих персональных данных.\n            "
                  )
                ])
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.isChecked,
          callback: function($$v) {
            _vm.isChecked = $$v
          },
          expression: "isChecked"
        }
      }),
      _vm._v(" "),
      _c("z-button", { staticClass: "illiquids-request-form__button" }, [
        _vm._v(_vm._s(_vm.text.button))
      ]),
      _vm._v(" "),
      _c("h5", { staticClass: "illiquids-request-form__note-title" }, [
        _vm._v("Примечание:")
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "illiquids-request-form__note-text" }, [
      _vm._v(
        "\n        Заявки на реализацию неликвидных материалов (МПЗ) принимаются также в письменном виде на имя Генерального директора. \n        Бланк письменной заявки можно скачать \n        "
      ),
      _c(
        "a",
        {
          staticClass: "link",
          attrs: {
            href: "/upload/Non_liquid_zajavka.xls",
            title:
              "Заявка на приобретение неликвидных материально-производственных запасов - файл MS-Excel (18 КБ)"
          }
        },
        [_vm._v("\n        отсюда\n        ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "illiquids-request-form__note-text" }, [
      _vm._v(
        "\n        Запросы в печатном виде принимаются по факсу +7(391) 259-14-88, почтой по адресу: 660049, г. Красноярск, ул. Бограда, 15. \n        Канцелярия АО «ЕРП» или на электронный адрес: "
      ),
      _c(
        "a",
        { staticClass: "link", attrs: { href: "mailto:erp@e-river.ru" } },
        [_vm._v("erp@e-river.ru")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1f601d19", { render: render, staticRenderFns: staticRenderFns })
  }
}