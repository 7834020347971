export const text = {
    pointOfDeparture: {
        title: 'Пункт отправления*',
        placeholder: 'Откуда отправляется груз (по возможности и причал погрузки)'
    },
    destination: {
        title: 'Пункт назначения*',
        placeholder: 'Куда доставляется груз (по возможности и причал выгрузки)'
    },
    departurePeriod: {
        title: 'Период отправления*',
        placeholder: 'Ориентировочная дата предъявления груза к перевозке (месяц, год)'
    },
    shippingName: {
        title: 'Наименование груза*',
        placeholder: ''
    },
    receiptOfCargo: {
        title: 'Поступление груза в пункт отправления (авто, ж/д, своим ходом)*',
        placeholder: ''
    },
    specifications: {
        title: 'Технические характеристики*',
        placeholder: ''
    },
    typeOfPackaging: {
        title: 'Вид упаковки*',
        placeholder: ''
    },
    numberOfCargo: {
        title: 'Количество грузовых мест, шт*',
        placeholder: ''
    },
    weightItem: {
        title: 'Масса одного грузового места брутто, т*',
        placeholder: ''
    },
    dimensions: {
        title: 'Габаритные размеры грузового места, м',
        placeholder: '',
        fields: {
            length: 'Длина*',
            width: 'Ширина/Диаметр*',
            height: 'Высота*'
        }
    },
    file: {
        title: '',
        placeholder: 'Перечень грузов в формате excel или word, ПТС на автомобиль'
    },
    organizationName: {
        title: 'Наименование организации*',
        placeholder: 'Название организации или ПБЮЛ, иначе укажите - «частное лицо»'
    },
    positionSupervisor: {
        title: 'Должность руководителя*',
        placeholder: ''
    },
    fioSupervisor: {
        title: 'ФИО руководителя*',
        placeholder: ''
    },
    fioContact: {
        title: 'ФИО контактного лица*',
        placeholder: '',
    },
    phoneContact: {
        title: 'Телефон*',
        placeholder: ''
    },
    emailContact: {
        title: 'Email*',
        placeholder: ''
    },
    additionalInfo: {
        title: 'Дополнительная информация',
        placeholder: 'Любая важная с Вашей точки зрения дополнительная информация'
    },
    captcha: 'Введите код с картинки*',
    button: 'Отправить',
    notificationRequiredTextError: 'Заполните все обязательные поля',
    notificationError: 'Ошибка отправки',
    notificationValidateError: 'Неправильный формат полей',
    notificationSuccess: 'Отправка успешна',
    notificationCaptchaError: 'Неверный код капчи. Попробуйте снова.',
    notificationCaptchaEmpty: 'Введите код с картинки',
    policy: 'Я ознакомился с Политикой о защите персональных данных и даю свое согласие на обработку моих персональных данных.'
}