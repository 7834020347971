var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.media_library_data && _vm.media_library_data.length
    ? _c("div", { staticClass: "media-library" }, [
        _c("h2", [_vm._v("Фотографии по теме")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.media_library_data, function(item, idx) {
            return _c("media-library-card", {
              key: idx,
              staticClass:
                "col-default-4 col-tablet-6 col-v-tablet-10 col-mobile-12",
              attrs: { item: item }
            })
          }),
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41d10cde", { render: render, staticRenderFns: staticRenderFns })
  }
}