var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        { staticClass: "projects-detail" },
        [
          _c("div", {
            staticClass: "projects-detail__text",
            domProps: { innerHTML: _vm._s(_vm.data.detailText) }
          }),
          _vm._v(" "),
          _vm.nav ? _c("page-changer", { attrs: { data: _vm.nav } }) : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-70a9cd40", { render: render, staticRenderFns: staticRenderFns })
  }
}