import Vue from 'vue'
import ContactCard from './ContactCard.vue'

const container = document.getElementById('contact-card')
const exists = typeof(container) != 'undefined' && container != null;

if (exists) MediaApp();

function MediaApp() {
    new Vue({
        el: '#contact-card',
        components: {
        	ContactCard
        }
    });
};