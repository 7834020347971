import moment from 'moment'
import 'moment/locale/ru'

// eslint-disable-next-line no-undef
const LANG = 'ru' //в App нет привычного lang и англйский язык не планируется
moment.locale(`${LANG}`)

export const momentFilter = (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') => {
    return isNaN(dataStr) ? moment(dataStr).format(pattern) : moment.unix(dataStr).utc().add(3, 'hours').format(pattern)
}

/**
 * Change format date
 * @name numberFilter
 * @param {number|string} dataStr - number
 * @param {object} options - contains {string} locale, {string} millesimal, {decimal} millesimal
 * @returns String
 */
export const numberFilter = (dataStr, options = {}) => {
    let settings = {
        locale: LANG === 'ru' ? 'ru-RU' : 'en-EN',
        millesimal: '',
        decimal: '.'
    }
    settings = {...settings, ...options}

    let result = dataStr.toString().replace(' ', '')
    result = result.replace(new RegExp(settings.millesimal, 'g'), '')
    result = result.replace(new RegExp(`[${settings.decimal}]`, 'g'), '.')
    result = Number(result)

    if (isNaN(result)) return dataStr

    return result.toLocaleString(settings.locale)
}
