<template>
    <div class="projects-detail" v-if="data">
        <div class="projects-detail__text" v-html="data.detailText" />
        <page-changer v-if="nav" :data="nav" />
    </div>
</template>

<script>
import PageChanger from '../app/PageChanger'

export default {
    name: 'projects-detail',
    components: {
        PageChanger
    },
    data () {
        return {
            data: this.$root.app.components?.['projects-detail'].item || {},
            nav: this.$root.app.components?.['projects-detail'].nav || {}
        }
    }
}
</script>

<style lang="scss">
.projects-detail {
    &__text {
        @include margin-level (bottom, M);

        & p {
            @include margin-level (top, XS);
        }

        & a {
            color: $token-colors-primary;
            text-decoration: none;
            font-weight: 400;
            -webkit-transition: color .2s ease,box-shadow .2s ease;
            transition: color .2s ease,box-shadow .2s ease;

            &:hover {
                color: $token-colors-link-hover;
            }
        }
    }

    &__city {
        color: $token-colors-black-50;
        font-weight: 600;
        line-height: 1.2;
        font-size: 20px;
        display: inline-flex;
        align-items: center;

        &:before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.8507 10.8825C18.8507 13.3518 17.5792 15.3873 15.9973 16.9649C14.5134 18.4448 12.8492 19.4251 12.0254 19.8605C11.2015 19.4251 9.53729 18.4448 8.05346 16.9649C6.47154 15.3873 5.2 13.3518 5.2 10.8825C5.2 7.11299 8.25582 4.05718 12.0254 4.05718C15.7949 4.05718 18.8507 7.11299 18.8507 10.8825ZM20.0507 10.8825C20.0507 16.701 14.0435 20.175 12.4237 21.006C12.1702 21.1361 11.8805 21.1361 11.627 21.006C10.0072 20.175 4 16.701 4 10.8825C4 6.45025 7.59308 2.85718 12.0254 2.85718C16.4576 2.85718 20.0507 6.45025 20.0507 10.8825ZM14.2648 10.8824C14.2648 12.1192 13.2621 13.1219 12.0253 13.1219C10.7885 13.1219 9.7859 12.1192 9.7859 10.8824C9.7859 9.64563 10.7885 8.643 12.0253 8.643C13.2621 8.643 14.2648 9.64563 14.2648 10.8824ZM15.4648 10.8824C15.4648 12.782 13.9249 14.3219 12.0253 14.3219C10.1258 14.3219 8.5859 12.782 8.5859 10.8824C8.5859 8.98289 10.1258 7.443 12.0253 7.443C13.9249 7.443 15.4648 8.98289 15.4648 10.8824Z' fill='%23626C84'/%3E%3C/svg%3E%0A");
            margin-right: $token-spacers-3-xs;
        }
    }
}
</style>