import Vue from 'vue'

const container = document.getElementById('suppliers-services')
const exists = typeof (container) != 'undefined' && container != null

import SuppliersServices from './SuppliersServices.vue'

if (exists) {
    new Vue({
        el: '#suppliers-services',
        components: {
            SuppliersServices,
        },
        data: {
            lang: getLang(),
            app: window.App
        },
    })
};
