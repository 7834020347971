<template>
    <div class="newspaper-page-item" v-if="itemData">
        <div class="newspaper-page-item__wrapper">
            <div class="newspaper-page-item__image" v-if="itemData.previewPicture.link">
                <img :src="itemData.previewPicture.link">
            </div>
            <div class="newspaper-page-item__info">
                <h2 class="newspaper-page-item__title">
                    {{ itemData.name }} от {{ formatDate(itemData.activeFrom) }}
                </h2>
                <a
                    v-if="itemData.readOnline.value"
                    :href="itemData.readOnline.value"
                    target="_blank"
                    class="link newspaper-page-item__online"
                >
                    Читать онлайн
                </a>
                <a
                    v-if="itemData.file.name"
                    :href="itemData.file.link"
                    class="link newspaper-page-item__link newspaper-page-item-link"
                    :download="itemData.file.name"
                    target="_blank"
                >
                    <span v-html="itemData.file.name"></span>
                    <div class="newspaper-page-item-link__info">
                        <span class="newspaper-page-item-link__type" v-html="itemData.file.type" />
                        <span class="newspaper-page-item-link__size" v-html="itemData.file.size" />
                    </div>
                </a>
            </div>
        </div>
        <template v-if="itemData.detailText && itemData.detailText.length">
            <button
                type="button"
                class="link newspaper-page-item-toggle"
                :class="{'is-opened': contentVisible}"
                @click="toggleBlock"
            >
                <span
                    class="newspaper-page-item-toggle__text"
                    v-html="contentVisible ? 'Скрыть содержание' : 'Смотреть содержание'"
                />
            </button>
            <transition :name="transitionName">
                <div
                    v-show="contentVisible"
                    class="newspaper-page-item__content"
                    v-html="itemData.detailText"
                />
            </transition>
        </template>
    </div>
</template>

<script>
import { momentFilter } from '../../../utils/filter.js'

export default {
    props: {
        itemData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            contentVisible: false,
            transitionName: '',
        };
    },
    methods: {
        formatDate (date) {
            return momentFilter(date, 'LL')
        },
        toggleBlock() {
            this.contentVisible = !this.contentVisible;
            this.transitionName = this.contentVisible ? 'fade-in' : 'fade-out';
        },
    },
};
</script>
