import Vue from 'vue';
import IlliquidsRequestForm from './IlliquidsRequestForm.vue'

const root = document.getElementById('illiquids-request-form');
const exists = typeof (root) != 'undefined' && root != null;

if (exists) IlliquidsRequestFormApp();

function IlliquidsRequestFormApp() {
    new Vue({
        el: '#illiquids-request-form',
        components: {
            IlliquidsRequestForm
        }
    });
};