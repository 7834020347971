import Vue from 'vue';
import ProjectsDetail from './ProjectsDetail.vue';

const container = document.getElementById('projects-detail');
const exists = typeof(container) != 'undefined' && container != null;

if (exists) ProjectsDetailApp();

function ProjectsDetailApp() {
    new Vue({
        el: '#projects-detail',
        components: {
            ProjectsDetail
        },
        data: {
            app: window.App
        },
    });
};
